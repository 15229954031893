import { Navigate } from 'react-router-dom';
import { useControllerSocialMediaContext } from '../../hooks/ControllerSocialMedia';
import { useEffect } from 'react';
import { PROVIDER_SOCIAL } from '../../data/Constants';
import RedirectLoader from './RedirectLoader';
import { getFacebookUserInformation } from '../../services/oauth';

let isLoading = false;

export default function FacebookRedirect() {
  const { sendToken } = useControllerSocialMediaContext();

  const url = window.location.href;
  const regex = /access_token=([^&]+)/;
  const match = url.match(regex);

  const accessToken = match?.[1];

  async function handleFacebookRedirect() {
    if (accessToken && !isLoading) {
      console.log('Autenticando via Facebook');
      isLoading = true;

      const facebookData = await getFacebookUserInformation(accessToken);

      sendToken(
        accessToken,
        PROVIDER_SOCIAL.FACEBOOK,
        'faceId',
        facebookData?.name
      );
    }
  }

  useEffect(() => {
    handleFacebookRedirect();
  }, []);

  if (!accessToken) return <Navigate to={'/'} />;

  return <RedirectLoader />;
}
