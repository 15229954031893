import { SentryContextsEnum } from '../utils/Sentry';

export const UNEXPECT_ERROR_MESSAGE =
  'Ops. Ocorreu um erro inesperado. Tente novamente mais tarde.';

export interface IErrorMessage {
  message: string;
  context: SentryContextsEnum;
  extras?: {
    [key: string]: any;
  };
}

export const ErrorMessages: {
  [key: string]: IErrorMessage;
} = {
  // Social
  // ---------------------------------------------------------
  SOCIAL: {
    message: 'Falha ao tentar se comunicar com provedor de login social',
    context: SentryContextsEnum.SIGNIN
  },

  // Sign In
  // ---------------------------------------------------------
  LOGIN_NATIVE: {
    message: 'Usuário não conseguiu fazer login nativo',
    context: SentryContextsEnum.SIGNIN
  },
  LOGIN_SOCIAL: {
    message: 'Usuário não conseguiu fazer login social',
    context: SentryContextsEnum.SIGNIN
  },
  ACCOUNT_STATUS: {
    message: 'Falha ao tentar verificar o status da conta do usuário',
    context: SentryContextsEnum.SIGNIN
  },

  // Social Merge
  MERGE_SOCIAL: {
    message: 'Falha ao conectar conta com conta social',
    context: SentryContextsEnum.SIGNIN
  },
  CHECK_SOCIAL_STATUS: {
    message:
      'Falha ao verificar se conta está disponível para conectar conta social',
    context: SentryContextsEnum.ACCOUNT_MENU_MERGE
  },
  DELETE_SOCIAL_MERGE: {
    message: 'Falha ao desconectar conta social',
    context: SentryContextsEnum.ACCOUNT_MENU_MERGE
  },

  // Sign UP
  CREATE_ACCOUNT_NATIVE: {
    message: 'Falha ao tentar criar nova conta/usuário',
    context: SentryContextsEnum.SIGNUP
  },
  CREATE_ACCOUNT_SOCIAL: {
    message: 'Falha ao tentar criar usuário social',
    context: SentryContextsEnum.SIGNUP
  },

  // E-mail
  // ---------------------------------------------------------
  EMAIL_EXIST_SIGN_UP: {
    message:
      'Falha ao tentar verificar o se existe uma conta com o e-mail digitado',
    context: SentryContextsEnum.SIGNUP
  },
  EMAIL_EXIST_FORGOT_PASSWORD: {
    message: 'Falha verificar se o e-mail digitado existe na base',
    context: SentryContextsEnum.FORGOT_PASSWORD
  },

  SEND_EMAIL_VALIDATE_ACCOUNT: {
    message: 'Falha ao tentar enviar e-mail para validar nova conta',
    context: SentryContextsEnum.SIGNUP
  },
  SEND_EMAIL_RECOVERY_PASSWORD: {
    message:
      'Falha ao tentar enviar e-mail para validar e-mail recuperação de senha',
    context: SentryContextsEnum.FORGOT_PASSWORD
  },

  // Cellphone
  // ---------------------------------------------------------
  PHONE_EXIST: {
    message:
      'Falha ao tentar verificar se existe uma conta com o número de celular digitado',
    context: SentryContextsEnum.SIGNUP
  },
  SEND_SMS_VALIDATE_PHONE: {
    message: 'Falha ao tentar enviar SMS para validar nova conta',
    context: SentryContextsEnum.SIGNUP
  },

  // Token
  // ---------------------------------------------------------
  CODE_EMAIL_VALIDATE_ACCOUNT: {
    message: 'Falha ao tentar validar código do e-mail de nova conta',
    context: SentryContextsEnum.SIGNUP
  },
  CODE_EMAIL_FORGOT_PASSWORD: {
    message:
      'Falha ao tentar validar código enviado por e-mail para recuperar senha',
    context: SentryContextsEnum.FORGOT_PASSWORD
  },
  CODE_PHONE_FORGOT_ACCOUNT: {
    message:
      'Falha ao tentar validar código enviado por SMS para recuperar conta',
    context: SentryContextsEnum.FORGOT_ACCOUNT
  },
  CODE_VALIDATE_PHONE: {
    message:
      'Falha ao tentar validar código enviado por SMS para cadastrar novo celular',
    context: SentryContextsEnum.SIGNUP
  },
  CODE_PHONE_ADD_PHONE: {
    message:
      'Falha ao tentar validar código enviado por SMS para cadastrar novo celular',
    context: SentryContextsEnum.ADD_PHONE
  },

  // Forgot Password
  // ---------------------------------------------------------
  CHECK_ACCOUNT_HAS_PASSWORD: {
    message: 'Falha ao verificar se a conta possui senha',
    context: SentryContextsEnum.FORGOT_PASSWORD
  },
  RESET_ACCOUNT_PASSWORD: {
    message: 'Falha ao resetar senha da conta',
    context: SentryContextsEnum.FORGOT_PASSWORD
  },
  GET_SOCIAL_CONNECTED_ACCOUNTS: {
    message: 'Falha ao consultar contas sociais associadas à conta do usuário',
    context: SentryContextsEnum.FORGOT_PASSWORD
  },

  // Forgot Account
  // ---------------------------------------------------------
  GET_ACCOUNT_INFO_FORGOT_ACCOUNT: {
    message:
      'Falha ao consultar o e-mail e as contas sociais associadas à conta do usuário',
    context: SentryContextsEnum.FORGOT_ACCOUNT
  },

  // Refresh Token
  // ---------------------------------------------------------
  REFRESH_TOKEN: {
    message: 'Falha ao obter novo access token',
    context: SentryContextsEnum.REFRESH_TOKEN
  },

  // Account
  // ---------------------------------------------------------
  GET_ACCOUNT_DATA: {
    message: 'Falha ao obter dados da conta',
    context: SentryContextsEnum.ACCOUNT
  },
  CHECK_CPF_EXIST: {
    message: 'Falha ao verificar se existe CPF na base',
    context: SentryContextsEnum.ACCOUNT
  },
  UPDATE_ACCOUNT_DATA: {
    message: 'Falha ao atualizar dados pessoais',
    context: SentryContextsEnum.ACCOUNT
  },
  UPDATE_ACCOUNT_ADDRESS: {
    message: 'Falha ao atualizar endereço',
    context: SentryContextsEnum.ACCOUNT
  },

  // CEP & City
  // ---------------------------------------------------------
  GET_CEP_INFO: {
    message: 'Falha ao obter endereço a partir do CEP digitado',
    context: SentryContextsEnum.ACCOUNT
  },
  GET_CITY_BY_NAME: {
    message: 'Falha ao obter cidade e estado a partir da cidade digitada',
    context: SentryContextsEnum.ACCOUNT
  },

  // Create Password
  // ---------------------------------------------------------
  CREATE_ACCOUNT_PASSWORD: {
    message: 'Falha ao criar senha da conta',
    context: SentryContextsEnum.CREATE_PASSWORD
  },

  // Change Password
  // ---------------------------------------------------------
  CONFIRM_ACCOUNT_PASSWORD: {
    message: 'Falha ao verificar senha atual da conta',
    context: SentryContextsEnum.CHANGE_PASSWORD
  },
  CHANGE_ACCOUNT_PASSWORD: {
    message: 'Falha ao alterar senha da conta',
    context: SentryContextsEnum.CHANGE_PASSWORD
  },

  // Add Phone
  // ---------------------------------------------------------
  UPDATE_ACCOUNT_TELEPHONE: {
    message: 'Falha ao adicionar número de celular da conta',
    context: SentryContextsEnum.ADD_PHONE
  },

  // Change Communication Preferences
  // ---------------------------------------------------------
  GET_COMMUNICATION_PREFERENCES: {
    message: 'Falha ao obter preferências de comunicação do usuário',
    context: SentryContextsEnum.CHANGE_COMMUNICATION_PREFERENCES
  },
  UPDATE_COMMUNICATION_PREFERENCES: {
    message: 'Falha ao atualizar preferências de comunicação do usuário',
    context: SentryContextsEnum.CHANGE_COMMUNICATION_PREFERENCES
  },
  GET_OPT_IN: {
    message: 'Falha ao obter opt-in do usuário',
    context: SentryContextsEnum.CHANGE_COMMUNICATION_PREFERENCES
  },
  UPDATE_OPT_IN: {
    message: 'Falha ao atualizar opt-in do usuário',
    context: SentryContextsEnum.CHANGE_COMMUNICATION_PREFERENCES
  },

  // Delete Account
  // ---------------------------------------------------------
  DELETE_ACCOUNT: {
    message: 'Falha ao deletar conta',
    context: SentryContextsEnum.DELETE_ACCOUNT
  },

  // Privacy & Terms
  // ---------------------------------------------------------
  GET_PRIVACY_AND_TERMS: {
    message:
      'Falha ao tentar obter textos de termos de uso e política de privacidade',
    context: SentryContextsEnum.TERMS
  }
};
