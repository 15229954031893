import { useEffect } from 'react';
import Card from '../../../../components/Card/Card';
import { useManagerPreferencesContext } from '../../../../data/ManagerPreferences/ManagerPreferences';
import { useControlNavBarContext } from '../../../../hooks/ControlNavBar';

export default function CardNotifications() {
  const { sizeView } = useManagerPreferencesContext();
  const { setShowButton, setGoBackNavigate } = useControlNavBarContext();

  function configGoBack() {
    setGoBackNavigate({
      path: '/menu-de-conta/preferencias-de-comunicacao',
      param: { state: 'refresh' }
    });
  }

  useEffect(() => {
    if (sizeView[0] <= 576) {
      setShowButton(true);
      configGoBack();
    }
  }, []);

  return (
    <Card
      width="100%"
      paddingHorizontal="24"
      paddingVertical="24"
      id="notification"
    >
      <div className="width-full flex flex-column">
        <div className="width-full">
          <h2 className="color-text-default mb-16">notificações</h2>
          <span className="lg color-text-default">
            O controle de notificações dos nossos aplicativos é feita no seu
            celular. Para saber como gerenciar essas opções, acesse as
            instruções das empresas responsáveis pelo sistema operacional do seu
            celular:
            <a
              className="link strong"
              target="_blank"
              href="https://support.apple.com/pt-br/HT201925#:~:text=mant%C3%AA%2Dlas%20separadas.-,Acesse%20Ajustes%20%3E%20Notifica%C3%A7%C3%B5es.,base%20nos%20alertas%20do%20app."
              rel="noreferrer"
            >
              {' '}
              iOS{' '}
            </a>{' '}
            ou{' '}
            <a
              className="link strong"
              target="_blank"
              rel="noreferrer"
              href="https://support.google.com/android/answer/9079661?hl=pt-BR"
            >
              {' '}
              Android.{' '}
            </a>
          </span>
        </div>
      </div>
    </Card>
  );
}
