import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertBox, {
  AlertBoxHandles
} from '../../../../components/AlertBox/AlertBox';
import Button from '../../../../components/Button/Button';
import ButtonLabel from '../../../../components/Button/ButtonLabel';
import FormFieldTokenNumber from '../../../../components/FormField/FormFieldTokenNumber';
import {
  CODE_INCORRECT_TOKEN,
  DEFAULT_API_COOLDOWNS
} from '../../../../data/Constants';
import { useUpdateUserDataContext } from '../../../../data/UpdateUserData/UpdateUserDataContext';
import { useControllerFlowStepsContext } from '../../../../hooks/ControllerFlowStepsContext';
import { useControlNavBarContext } from '../../../../hooks/ControlNavBar';
import {
  createTokenValidatePhone,
  validateToken
} from '../../../../services/tokenValidation';
import { SentryContextsEnum } from '../../../../utils/Sentry';
import { ErrorMessages } from '../../../../data/ErrorMessages';
import { AttemptsTypeEnum } from '../../../../enums/AttemptsTypeEnum';
import { convertSecondsToMMSS } from '../../../../utils/GenericFunctions';

export interface RegisterPhoneCheckTokenProps {
  previousPath?: number | null;
  nextPath?: number | null;
  handleChangeStep(e: number): void;
}

export default function RegisterPhoneCheckToken({
  previousPath,
  nextPath,
  handleChangeStep
}: RegisterPhoneCheckTokenProps) {
  const {
    showErrorMessageServer,
    showCooldownErrorMessage,
    attempts,
    increaseAttemptByType
  } = useControllerFlowStepsContext();
  const { getValues, updatedPhone } = useUpdateUserDataContext();
  const { setShowButton, setGoBackNavigate } = useControlNavBarContext();
  const [fieldValidate, setValueFieldValidate] = useState({
    value: '',
    isValid: false
  });
  const [errorToken, setErrorToken] = useState(false);
  const [resendingCode, setResendingCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [runTime, setRunTime] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    timeOutResend(attempts?.VALIDATE_PHONE);
    setShowButton(true);
    setGoBackNavigate({ path: '', param: {} });
  }, []);

  function timeOutResend(intervalPos: number) {
    setRunTime(true);
    let initSeconds = DEFAULT_API_COOLDOWNS[intervalPos] || 60;
    setSeconds(initSeconds);
    const interval = setInterval(() => {
      if (initSeconds == 0) {
        clearInterval(interval);
        setSeconds(0);
        setRunTime(false);
      } else {
        setSeconds(--initSeconds);
      }
    }, 1000);
  }

  useEffect(() => {
    navigate('../token', { relative: 'path' });
  }, [navigate]);

  window.onpopstate = () => {
    previousStep();
  };

  function previousStep() {
    if (previousPath == 0 || previousPath) {
      handleChangeStep(previousPath);
    }
  }

  async function checkToken() {
    let valueKey = getValues('cellPhoneNumber');
    valueKey = valueKey?.replace(/\D/g, '');

    if (valueKey && fieldValidate.value) {
      setLoading(true);
      try {
        await validateToken(
          valueKey,
          fieldValidate.value,
          ErrorMessages.CODE_PHONE_ADD_PHONE
        );
        nextStep();
      } catch (error: any) {
        if (error?.code === CODE_INCORRECT_TOKEN) {
          setErrorToken(true);
        } else {
          console.error(error);
          showErrorMessageServer();
        }
        setResendingCode(false);
        setLoading(false);
      }
    }
  }

  async function resendCode() {
    setErrorToken(false);
    setResendingCode(true);
    await postCreateToken();
    increaseAttemptByType(AttemptsTypeEnum.VALIDATE_PHONE);
    timeOutResend(attempts.VALIDATE_PHONE + 1);
  }

  async function postCreateToken() {
    const phone = getValues('cellPhoneNumber');
    const formatValue = phone?.replace(/\D/g, '');
    setLoading(true);
    try {
      await createTokenValidatePhone(
        formatValue,
        updatedPhone
          ? SentryContextsEnum.CHANGE_PHONE
          : SentryContextsEnum.ADD_PHONE
      );
      openAlert();
      setLoading(false);
    } catch (error: any) {
      if (error?.statusCode === 429) showCooldownErrorMessage();
      else showErrorMessageServer();
      setLoading(false);
    }
  }

  const alertBoxRef = useRef<AlertBoxHandles>(null);
  function openAlert() {
    alertBoxRef.current?.openAlert();
  }

  function getPhoneNumber() {
    const phone = getValues('cellPhoneNumber');
    if (!phone) return '';
    try {
      const [areaCode, number] = phone.split(' ');
      const splitNumber = number.split('-');
      return `${areaCode} XXXXX-${splitNumber[1]}`;
    } catch (error) {
      return '';
    }
  }

  function nextStep() {
    if (nextPath) {
      handleChangeStep(nextPath);
    }
  }

  return (
    <>
      <div className="flex flex-column">
        <h2 className="color-text-default">
          {updatedPhone ? 'alterar celular' : 'incluir celular'}
        </h2>
        <h6 className="mti-8 mbi-8 color-text-default">
          Informe o código de validação
        </h6>
        <span className="md mt-4 mb-24">
          Digite o código recebido por SMS no número
          <br />
          <span className="md-bold-700">+55 {getPhoneNumber()}</span>
          <span className="link ml-6" onClick={previousStep}>
            alterar número
          </span>
        </span>

        <FormFieldTokenNumber
          fieldValidate={fieldValidate}
          errorToken={errorToken}
          setState={setValueFieldValidate}
          resendingCode={resendingCode}
        />

        <div className="flex flex-column mt-16">
          <Button
            className="mt-8 mbi-0"
            full
            onClick={checkToken}
            loading={loading}
            disabled={!fieldValidate.isValid}
          >
            <ButtonLabel loading={loading}>confirmar código</ButtonLabel>
          </Button>
          <Button
            className="mt-16 mbi-0"
            customColors="btn-outline"
            full
            onClick={resendCode}
            loading={loading}
            disabled={runTime}
          >
            <ButtonLabel>
              reenviar código {convertSecondsToMMSS(seconds)}
            </ButtonLabel>
          </Button>
          <Button
            className="mt-16"
            customColors="btn-white"
            full
            onClick={previousStep}
          >
            <ButtonLabel>voltar</ButtonLabel>
          </Button>
        </div>
      </div>
      <AlertBox ref={alertBoxRef} text="código enviado com sucesso" />
    </>
  );
}
