import { ErrorMessages, IErrorMessage } from '../data/ErrorMessages';
import { IUser } from '../model/Models';
import { SentryContextsEnum } from '../utils/Sentry';
import { GET } from './api';

const ACCOUNT_SERVICE = process.env.REACT_APP_BASE_URL_ACCOUNT_SERVICE;

export async function checkEmailExist(
  email: string,
  errorMessage: IErrorMessage = ErrorMessages.EMAIL_EXIST_SIGN_UP
) {
  const response = await GET(
    `${ACCOUNT_SERVICE}/api/v1/email/${email}`,
    errorMessage
  );
  return response.data;
}

export async function checkExistNumberPhone(
  number: string,
  context?: SentryContextsEnum
) {
  const customErrorMessage = ErrorMessages.PHONE_EXIST;
  if (context) customErrorMessage.context = context;

  const response = await GET(
    `${ACCOUNT_SERVICE}/api/v1/phone/${number}`,
    ErrorMessages.PHONE_EXIST
  );
  return response.data;
}

export async function checkExistsCpf(cpf: string) {
  const response = await GET(
    `${ACCOUNT_SERVICE}/api/v1/${cpf}/cpf`,
    ErrorMessages.CHECK_CPF_EXIST
  );
  return response.data;
}

interface TermsResponse {
  id: number;
  privacyPolicy: string;
  terms: string;
  version: number;
}

export async function getTermsAndConditions(): Promise<TermsResponse> {
  const response = await GET(
    `${ACCOUNT_SERVICE}/api/v1/privacy-policy`,
    ErrorMessages.GET_PRIVACY_AND_TERMS
  );
  return response.data;
}

export async function getCep(cep: string) {
  const response = await GET(
    `${ACCOUNT_SERVICE}/api/v1/cep/${cep}`,
    ErrorMessages.GET_CEP_INFO
  );
  return response.data;
}

export async function getCityUs(city: string) {
  const response = await GET(
    `${ACCOUNT_SERVICE}/api/v1/city/${city}`,
    ErrorMessages.GET_CITY_BY_NAME
  );
  return response.data;
}

export async function getSocialsLogin(cellphone: string) {
  const response = await GET(
    `${ACCOUNT_SERVICE}/api/v1/social/phone/${cellphone}`,
    ErrorMessages.GET_ACCOUNT_INFO_FORGOT_ACCOUNT
  );
  return response.data;
}

export async function getSocialsLoginByEmail(email: string) {
  const response = await GET(
    `${ACCOUNT_SERVICE}/api/v1/social/email/${email}`,
    ErrorMessages.GET_SOCIAL_CONNECTED_ACCOUNTS
  );
  return response.data;
}

export async function sessionUserInfo(): Promise<IUser> {
  const response = await GET(
    `${ACCOUNT_SERVICE}/api/v1/account`,
    ErrorMessages.GET_ACCOUNT_DATA
  );

  return response.data;
}
