import { yupResolver } from '@hookform/resolvers/yup';
import { createContext, useContext, useEffect, useState } from 'react';
import {
  FieldErrorsImpl,
  useForm,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch
} from 'react-hook-form';
import { createUser } from '../../services/user';
import {
  clearSpecialCharactersFromNumber,
  formatDateToSend,
  isWebview
} from '../../utils/GenericFunctions';
import { createFormSign, FormSignInValues } from './FormSignIn';
import { useAuthContext } from '../../hooks/AuthContext';
import { ICreateUserRequest } from '../../interfaces/user/ICreateUser';

interface ContextData {
  register: UseFormRegister<any>;
  handleSubmit: UseFormHandleSubmit<any>;
  errors: Partial<FieldErrorsImpl<any>>;
  touchedFields: Partial<Readonly<any>>;
  trigger: UseFormTrigger<any>;
  originDomainForm: string | null;
  submitForm(payload: any, callBack: any): void;
  watch: UseFormWatch<any>;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  setFocus: UseFormSetFocus<any>;
  setError: UseFormSetError<any>;
  isValid: boolean;
}

export const SignInContext = createContext({} as ContextData);

const SignInProvider = ({ children }: any) => {
  const [originDomainForm, setOrigin] = useState<any>('');
  // create Form Fields
  const formFields = createFormSign();
  const {
    register,
    trigger,
    watch,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    setError,
    formState: { errors, touchedFields, isValid }
  } = useForm<FormSignInValues>({
    mode: 'onChange',
    resolver: yupResolver(formFields, { abortEarly: false })
  });
  const { getSocialSession } = useAuthContext();

  useEffect(() => {
    const regexOrigin = /videos/g;
    const regexAppOrigin = /sbt/g;
    if (regexOrigin.test(window.location.pathname)) {
      setOrigin('videos/signin');
    } else if (regexAppOrigin.test(window.location.pathname)) {
      setOrigin('sbt/signin');
    } else {
      setOrigin('signin');
    }
  }, []);

  async function submitForm(formValues: any, callBack: any, provider?: string) {
    const valuesSubmit: ICreateUserRequest = {
      userName: formValues.userName?.trim(),
      acceptTermsOfUse: formValues.acceptTermsOfUse,
      birthDate: formatDateToSend(formValues.birthDate),

      email: formValues.email,
      emailCode: formValues.emailCode,

      cellPhoneNumber: clearSpecialCharactersFromNumber(
        formValues.cellPhoneNumber
      ),

      socialName: formValues?.socialName?.trim() || ''
    };

    if (!provider) {
      valuesSubmit['password'] = formValues.password;
    } else {
      valuesSubmit['provider'] = provider;
      valuesSubmit['providerToken'] = getSocialSession();
    }

    try {
      await createUser(valuesSubmit);
      callBack();
    } catch (error) {
      console.log('submitform error', error);
    }
  }

  function onCloseSession() {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const YoutubePlayerView = window.YoutubePlayerView;
      YoutubePlayerView.onCloseSession();
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (isWebview()) onCloseSession();
  }, []);

  return (
    <SignInContext.Provider
      value={{
        originDomainForm,
        register,
        handleSubmit,
        errors,
        submitForm,
        touchedFields,
        trigger,
        getValues,
        setValue,
        setFocus,
        watch,
        setError,
        isValid
      }}
    >
      {children}
    </SignInContext.Provider>
  );
};
export const useSignInContext = () => useContext(SignInContext);
export default SignInProvider;
