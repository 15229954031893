import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoText from '../../components/LogoText/LogoText';
import { useControllerFlowStepsContext } from '../../hooks/ControllerFlowStepsContext';
import { getTermsAndConditions } from '../../services/queryController';
import './Terms.scss';
import Modal, { ModalHandles } from '../../components/Modal/Modal';
import ModalTitle from '../../components/Modal/ModalTitle';
import ModalDescription from '../../components/Modal/ModalDescription';
import Icon from '../../components/Icon/Icon';
interface TermsProps {
  allowSkipStep?: boolean;
  previousPath?: number | null;
  nextPath?: number | null;
  handleChangeStep(e: number): void;
  context: any;
}

interface TermsResponse {
  id: number;
  privacyPolicy: string;
  terms: string;
  version: number;
}

export default function Terms({
  handleChangeStep,
  previousPath,
  context
}: TermsProps) {
  const { setNotCreateAccount, setLoadingFullScreen } =
    useControllerFlowStepsContext();
  const navigate = useNavigate();
  const { register, setValue } = useContext<any>(context);
  const [termsData, setTermsData] = useState<TermsResponse | null>(null);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState('');
  const [termJustSet, setTermJustSet] = useState(false);

  const modalRef = useRef<ModalHandles>(null);

  useEffect(() => {
    setTermJustSet(false);
    setTermsAndConditions(false);
    setValue('acceptTermsOfUse', false);
  }, []);

  useEffect(() => {
    setNotCreateAccount(false);
    document.querySelector('.container')?.scrollTo?.({ top: 0 });
  }, [navigate]);

  window.onpopstate = () => {
    controlBackPage();
  };

  function controlBackPage() {
    if (previousPath == 0 || previousPath) {
      handleChangeStep(previousPath);
    }
  }

  useEffect(() => {
    if (isOpenModal && !termJustSet) {
      setTerms();
    }
  }, [isOpenModal]);

  async function checkHasTerms() {
    const response = await getTermsAndConditions();
    setTermsData(response);
  }

  function setTerms() {
    setTermJustSet(true);
    setLoadingFullScreen(true);
    checkHasTerms().finally(() => {
      setLoadingFullScreen(false);
    });
  }

  function acceptTermsAndConditions() {
    setTermsAndConditions((state) => !state);
    setValue('acceptTermsOfUse', !termsAndConditions);
  }

  function handleOpenModal(e: React.MouseEvent<HTMLSpanElement>, type: string) {
    e.preventDefault();
    modalRef.current?.openModal();
    setIsOpenModal(type);
  }

  function closeModal() {
    setIsOpenModal('');
    modalRef.current?.closeModal();
  }

  return (
    <div className="flex flex-column">
      <div className="flex flex-column">
        <label htmlFor="checkPolicy" className="check-box mb-24 mt-16">
          <span className="md">
            Ao criar minha conta, eu declaro que li e aceito os
            <span
              className="strong ml-3 mr-3 link-intern-text text-purple link"
              onClick={(e) => handleOpenModal(e, 'terms')}
            >
              Termos de uso
            </span>
            e a{' '}
            <span
              className="strong ml-3 mr-3 link-intern-text text-purple link"
              onClick={(e) => handleOpenModal(e, 'policy')}
            >
              Politica de privacidade{' '}
            </span>
            dos produtos e serviços do SBT.
          </span>

          <input
            alt="aceitar termos e condições"
            type="checkbox"
            id="checkPolicy"
            onClick={acceptTermsAndConditions}
            {...register('acceptTermsOfUse')}
          />
          <span className="check-box-checked"></span>
        </label>
      </div>

      <div className="policy-modal-container">
        <Modal ref={modalRef}>
          {isOpenModal && (
            <>
              <div className="title-container">
                <LogoText />
                <Icon
                  name="closeRounded"
                  className="close-icon"
                  onClick={closeModal}
                />
                <ModalTitle>
                  {isOpenModal === 'policy'
                    ? 'política de privacidade'
                    : 'termos de uso'}
                </ModalTitle>
              </div>
              <ModalDescription>
                <p
                  className="md color-text-default"
                  style={
                    isOpenModal === 'policy'
                      ? { whiteSpace: 'break-spaces' }
                      : {}
                  }
                  dangerouslySetInnerHTML={{
                    __html:
                      (isOpenModal === 'policy'
                        ? termsData?.privacyPolicy
                        : termsData?.terms) || ''
                  }}
                />
              </ModalDescription>
            </>
          )}
        </Modal>
      </div>
    </div>
  );
}
