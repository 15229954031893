import { Navigate } from 'react-router-dom';
import { useControllerSocialMediaContext } from '../../hooks/ControllerSocialMedia';
import { useEffect } from 'react';
import RedirectLoader from './RedirectLoader';

let isLoading = false;

export default function GoogleRedirect() {
  const { googleHandleCredentialResponse } = useControllerSocialMediaContext();

  const url = window.location.href;
  const regex = /id_token=([^&]+)/;
  const match = url.match(regex);

  async function handleGoogleRedirect() {
    if (match && !isLoading) {
      console.log('Autenticado pelo Google');
      isLoading = true;

      const googleResponse = {
        // TODO obter access token
        accessToken: '',
        tokenId: match?.[1]
      };
      googleHandleCredentialResponse(googleResponse);
    }
  }

  useEffect(() => {
    handleGoogleRedirect();
  }, []);

  if (!match) return <Navigate to={'/'} />;

  return <RedirectLoader />;
}
