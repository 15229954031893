import Loading from '../../components/Loading/Loading';

import './RedirectLoader.scss';

export default function RedirectLoader() {
  return (
    <div className="loading-full-screen">
      <Loading />
    </div>
  );
}
