import * as yup from 'yup';
import {
  schemaBoolRequiredSocialName,
  schemaCheckPasswordRequired,
  schemaEmailRequired,
  schemaFullName,
  schemaPasswordRequired,
  schemaSocialName,
  schemaStringDateRequired,
  schemaStringPhoneRequired,
  schemaTermsRequired
} from '../SchemaFieldsRequired';

export interface FormSignUpValues {
  email: string;
  password: string;
  checkPassword: string;
  acceptTermsOfUse: boolean;
  userName: string;
  socialName: string;
  hasSocialName: boolean;
  birthDate: string;
  cellPhoneNumber?: string;
  socialId?: string;
  provider?: string;
  isSocial?: boolean;
}

export function createForm() {
  const objectFiels = {
    email: schemaEmailRequired,
    password: schemaPasswordRequired,
    checkPassword: schemaCheckPasswordRequired,
    acceptTermsOfUse: schemaTermsRequired,
    userName: schemaFullName,
    socialName: schemaSocialName,
    hasSocialName: schemaBoolRequiredSocialName,
    birthDate: schemaStringDateRequired,
    cellPhoneNumber: schemaStringPhoneRequired,
    provider: yup.string(),
    socialId: yup.string()
  };

  return yup.object(objectFiels);
}
