import Success from '../../components/Success/Success';
import { SIGNIN_SUCCESS_PAGE_VALIDATE_EMAIL } from '../../data/Constants';
import './Success.scss';

interface SuccessSignInProps {
  updateData?: boolean;
  context: any;
  viewSuccess?: any;
}

export default function SuccessSignIn({
  viewSuccess,
  context
}: SuccessSignInProps) {
  return (
    <Success context={context}>
      {viewSuccess === SIGNIN_SUCCESS_PAGE_VALIDATE_EMAIL ? (
        <span className="md mb-24 color-text-default">
          O e-mail da sua Conta SBT foi validado.
          <br /> agora é só alegria!
        </span>
      ) : (
        <span className="md mb-24 color-text-default">
          agradecemos por completar os
          <br /> dados da sua Conta SBT!
        </span>
      )}
    </Success>
  );
}
