import * as yup from 'yup';
import {
  checkSocialName,
  hasPhoneNumbersInvalid,
  testDateIsValid,
  testFormatDate,
  testIsEqualOrGreat18Years,
  testIsGreatHundredAge,
  validatedCPF
} from '../utils/GenericFunctions';
import { GENRE } from './Constants';

export const schemaEmailRequired = yup.string().when('provider', {
  is: (provider: string) => !!provider, // Verifica se o campo provider tem um valor
  then: yup.string().email('Formato de e-mail inválido'),
  otherwise: yup
    .string()
    .required('Campo obrigatório')
    .email('Formato de e-mail inválido')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      {
        message: 'Formato de email inválido'
      }
    )
});

export const schemaStringCpfRequired = yup
  .string()
  .required('Campo obrigatório')
  .matches(
    /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}/g,
    'formato do CPF inválido'
  )
  .test('check-cpf', 'Formato do CPF inválido', (value) => {
    return typeof value == 'string' && validatedCPF(value);
  });
export const schemaPasswordRequired = yup.string().when('isSocial', {
  is: false,
  then: (schema) => schema.required('Campo obrigatório')
});
export const schemaCheckPasswordRequired = yup
  .string()
  .when('isSocial', {
    is: false,
    then: (schema) => schema.required('Campo obrigatório')
  })
  .test('check-password', 'as senhas não são iguais', (value, context) => {
    if (context.parent.password == value) {
      return true;
    }

    return false;
  });
export const schemaTermsRequired = yup.bool().when('isSocial', {
  is: false,
  then: (schema) => schema.required('Campo obrigatório')
});
export const schemaStringRequired = yup.string().required('Campo obrigatório');
export const schemaStringPhoneRequired = yup.lazy((value) =>
  !value
    ? yup.string()
    : yup
        .string()
        .required('Campo obrigatório')
        .matches(/(\(\d{2}\))\s(\d{5})-(\d{4})/, 'número inválido')
        .test('number-invalid', (value, context) => {
          if (
            value &&
            typeof value == 'string' &&
            hasPhoneNumbersInvalid(value)
          ) {
            return context.createError({
              message: 'número inválido'
            });
          }
          return true;
        })
);

export const schemaCepRequired = yup
  .string()
  .required('Campo obrigatório')
  .matches(/[0-9]{5}-[0-9]{3}/g, 'cep inválido');
export const schemaStringDateRequired = yup
  .string()
  .required('Campo obrigatório')
  .test('valid-date', (value, context) => {
    if (typeof value == 'string' && testFormatDate(value)) {
      if (testDateIsValid(value)) {
        if (!testIsEqualOrGreat18Years(value)) {
          return context.createError({
            message: 'ops! você deve ter mais de 18 anos para criar uma conta'
          });
        }
        if (testIsGreatHundredAge(value)) {
          return context.createError({ message: 'data inválida' });
        }
        return true;
      }
      return context.createError({ message: 'data inválida' });
    }

    return context.createError({ message: 'data inválida' });
  });
export const schemaBoolRequired = yup.bool().required('Campo obrigatório');
export const schemaBoolRequiredSocialName = yup
  .bool()
  .test('useNameSocial', (value, context) => {
    if (context.parent?.socialName?.length < 1 && value) {
      return context.createError({
        path: 'socialName',
        message: 'Campo obrigatório'
      });
    }

    return true;
  });
export const schemaNumberGenrer = yup
  .string()
  .test('gender', (value, context) => {
    if (context.parent?.gender?.length < 1 && value == GENRE.OTHER) {
      return context.createError({
        path: 'gender',
        message: 'Campo obrigatório'
      });
    }

    return true;
  });

export const schemaNoNumber = yup
  .string()
  .test('check-hasNumber', 'Campo obrigatório', (value, context) => {
    if (!value?.length && context.parent.hasAddressNumber) {
      return false;
    }
    return true;
  });

export const schemaFullName = yup
  .string()
  .required('Campo obrigatório')
  .test('check-full-name', 'digite seu nome e sobrenome', (value) => {
    value = value?.trim();
    if (
      value &&
      value.trim().split(' ')[0] &&
      value.trim().split(' ')[0]?.length > 1 &&
      value.trim().split(' ')[1] &&
      value.trim().split(' ')[1]?.length > 1 &&
      value?.indexOf('  ') === -1
    ) {
      return true;
    }
    return false;
  });

export const schemaSocialName = yup
  .string()
  .test(
    'check-social-name',
    'digite seu nome e sobrenome',
    (value, context) => {
      if (context.parent?.hasSocialName)
        if (!value || checkSocialName(value)) return false;

      return true;
    }
  );
