import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertBox, {
  AlertBoxHandles
} from '../../../components/AlertBox/AlertBox';
import Button from '../../../components/Button/Button';
import ButtonLabel from '../../../components/Button/ButtonLabel';
import FormFieldTokenNumber from '../../../components/FormField/FormFieldTokenNumber';
import LogoText from '../../../components/LogoText/LogoText';
import { useAccountRecoveryContext } from '../../../data/AccountRecovery/AccountRecoveryContext';
import {
  CODE_INCORRECT_TOKEN,
  DEFAULT_API_COOLDOWNS
} from '../../../data/Constants';
import { useControllerFlowStepsContext } from '../../../hooks/ControllerFlowStepsContext';
import {
  createTokenValidatePhone,
  validateToken
} from '../../../services/tokenValidation';
import { SentryContextsEnum } from '../../../utils/Sentry';
import { ErrorMessages } from '../../../data/ErrorMessages';
import { AttemptsTypeEnum } from '../../../enums/AttemptsTypeEnum';
import { convertSecondsToMMSS } from '../../../utils/GenericFunctions';

export interface RecoveryAccountCheckTokenProps {
  allowSkipStep?: boolean;
  previousPath?: number | null;
  nextPath?: number | null;
  handleChangeStep(e: number): void;
  context?: any;
  isEmail?: boolean;
}

export default function RecoveryAccountCheckToken({
  previousPath,
  nextPath,
  handleChangeStep,
  isEmail
}: RecoveryAccountCheckTokenProps) {
  const {
    getOrigin,
    showErrorMessageServer,
    attempts,
    increaseAttemptByType,
    showCooldownErrorMessage
  } = useControllerFlowStepsContext();
  const { getValues } = useAccountRecoveryContext();
  const [fieldValidate, setValueFieldValidate] = useState({
    value: '',
    isValid: false
  });
  const [errorToken, setErrorToken] = useState(false);
  const [resendingCode, setResendingCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [runTime, setRunTime] = useState(false);
  const url = `${
    !getOrigin()
      ? '/recuperar-conta/token'
      : '/' + getOrigin() + '/recuperar-conta/token'
  }`;
  const navigate = useNavigate();

  useEffect(() => {
    timeOutResend(attempts?.VALIDATE_PHONE);
  }, []);

  function timeOutResend(intervalPos: number) {
    setRunTime(true);
    let initSeconds = DEFAULT_API_COOLDOWNS[intervalPos] || 60;
    setSeconds(initSeconds);
    const interval = setInterval(() => {
      if (initSeconds == 0) {
        clearInterval(interval);
        setSeconds(0);
        setRunTime(false);
      } else {
        setSeconds(--initSeconds);
      }
    }, 1000);
  }

  useEffect(() => {
    navigate(url);
  }, [navigate]);

  window.onpopstate = () => {
    previousStep();
  };

  function previousStep() {
    if (previousPath == 0 || previousPath) {
      handleChangeStep(previousPath);
    }
  }

  async function checkToken() {
    let valueKey = getValues('cellPhoneNumber');
    valueKey = valueKey?.replace(/\D/g, '');

    if (valueKey && fieldValidate.value) {
      setLoading(true);
      try {
        await validateToken(
          valueKey,
          fieldValidate.value,
          ErrorMessages.CODE_PHONE_FORGOT_ACCOUNT
        );
        nextStep();
      } catch (error: any) {
        if (error?.code === CODE_INCORRECT_TOKEN) {
          setErrorToken(true);
        } else {
          showErrorMessageServer();
        }
        setResendingCode(false);
        setLoading(false);
      }
    }
  }

  async function resendCode() {
    setErrorToken(false);
    setResendingCode(true);
    await postCreateToken();
    increaseAttemptByType(AttemptsTypeEnum.VALIDATE_PHONE);
    timeOutResend(attempts.VALIDATE_PHONE + 1);
  }

  async function postCreateToken() {
    const email = getValues('cellPhoneNumber');
    const formatValue = email?.replace(/\D/g, '');
    setLoading(true);
    try {
      await createTokenValidatePhone(
        formatValue,
        SentryContextsEnum.FORGOT_ACCOUNT
      );
      openAlert();
    } catch (error: any) {
      if (error?.statusCode === 429) showCooldownErrorMessage();
      else showErrorMessageServer();
    } finally {
      setLoading(false);
    }
  }

  const alertBoxRef = useRef<AlertBoxHandles>(null);
  function openAlert() {
    alertBoxRef.current?.openAlert();
  }

  function getPhoneNumber() {
    const phone = getValues('cellPhoneNumber');
    if (!phone) return '';
    try {
      const [areaCode, number] = phone.split(' ');
      const splitNumber = number.split('-');
      return `${areaCode} XXXXX-${splitNumber[1]}`;
    } catch (error) {
      return '';
    }
  }

  function nextStep() {
    if (nextPath) {
      handleChangeStep(nextPath);
    }
  }

  return (
    <>
      <div className="flex flex-column">
        <LogoText />
        <h2 className="color-text-default">esqueceu seu e-mail?</h2>
        <h6 className="mti-8 mbi-8 color-text-default">
          Informe o código de validação
        </h6>
        {isEmail ? (
          <span className="md mt-4 mb-24 color-text-default">
            Digite o código enviado para o e-mail
            <br />
            <span className="md-bold-700">{getValues('email')}</span>
            {origin === 'signup' ||
            origin === 'videos/signup' ||
            origin === 'sbt/signup' ? (
              <span className="link ml-6" onClick={previousStep}>
                alterar e-mail
              </span>
            ) : null}
          </span>
        ) : (
          <span className="md mt-4 mb-24">
            Digite o código recebido por SMS no número
            <br />
            <span className="md-bold-700">+55 {getPhoneNumber()}</span>
            <span className="link ml-6" onClick={previousStep}>
              alterar número
            </span>
          </span>
        )}

        <FormFieldTokenNumber
          fieldValidate={fieldValidate}
          errorToken={errorToken}
          setState={setValueFieldValidate}
          resendingCode={resendingCode}
        />

        <div className="flex flex-column mt-16">
          <Button
            className="mt-8 mbi-0"
            full
            onClick={checkToken}
            loading={loading}
            disabled={!fieldValidate.isValid}
          >
            <ButtonLabel loading={loading}>confirmar código</ButtonLabel>
          </Button>
          <Button
            className="mt-16 mbi-0"
            customColors="btn-outline"
            full
            onClick={resendCode}
            loading={loading}
            disabled={runTime}
          >
            <ButtonLabel>
              reenviar código {convertSecondsToMMSS(seconds)}
            </ButtonLabel>
          </Button>
          <Button
            className="mt-16"
            customColors="btn-white"
            full
            onClick={previousStep}
          >
            <ButtonLabel>voltar</ButtonLabel>
          </Button>
        </div>
      </div>
      <AlertBox ref={alertBoxRef} text="código enviado com sucesso" />
    </>
  );
}
