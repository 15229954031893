import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import ButtonLabel from '../../../components/Button/ButtonLabel';
import Icon from '../../../components/Icon/Icon';
import LogoText from '../../../components/LogoText/LogoText';
import { useControllerFlowStepsContext } from '../../../hooks/ControllerFlowStepsContext';

export default function PasswordRecoveryWithoutPassword() {
  const [accountInfos, setAccountInfos] = useState({} as any);
  const [existsSocialLogin, setExistsSocialLogin] = useState(false);

  const navigate = useNavigate();

  const { getOrigin, controllerViews } = useControllerFlowStepsContext();

  const url = `${
    !getOrigin()
      ? '/recuperar-senha/informacoes-login'
      : '/' + getOrigin() + '/recuperar-senha/informacoes-login'
  }`;

  useEffect(() => {
    navigate(url);
  }, [navigate]);

  useEffect(() => {
    setAccountInfos(controllerViews.socialData);

    if (
      controllerViews.socialData &&
      (controllerViews.socialData.loginFace ||
        controllerViews.socialData.loginGoogle ||
        controllerViews.socialData.loginApple)
    )
      setExistsSocialLogin(true);
  }, []);

  function showIconFace() {
    return accountInfos.loginFace ? (
      <div>
        <Icon name="logoFace" />
        <span className="md-bold-700">Facebook</span>
      </div>
    ) : null;
  }

  function showIconGoogle() {
    return accountInfos.loginGoogle ? (
      <div>
        <Icon name="logoGoogle" />
        <span className="md-bold-700">Google</span>
      </div>
    ) : null;
  }

  function showIconApple() {
    return accountInfos.loginApple ? (
      <div>
        <Icon name="logoApple" />
        <span className="md-bold-700">Apple</span>
      </div>
    ) : null;
  }

  function redirect() {
    window.open(`/${getOrigin()}`, '_self');
  }

  return (
    <>
      <div className="flex flex-column">
        <LogoText />
        <h2 className="mt-15 color-text-default">sua conta não possui senha</h2>
        <span className="md mt-8">
          Identificamos que sua Conta SBT está conectada a uma conta externa.
        </span>
        <span className="md mt-4">
          Use sua conta conectada para fazer login.
        </span>

        <div className="flex flex-column mt-16">
          {existsSocialLogin ? (
            <>
              <span className="lg strong mb-0">contas conectadas</span>
              <div className="flex social-medias-icons mb-24">
                {showIconFace()}
                {showIconGoogle()}
                {showIconApple()}
              </div>
            </>
          ) : null}

          <Button className="mbi-0" full onClick={redirect}>
            <ButtonLabel>fazer login</ButtonLabel>
          </Button>
        </div>
      </div>
    </>
  );
}
