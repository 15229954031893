import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Card from '../../../../components/Card/Card';
import FormFieldToggle from '../../../../components/FormField/FormFieldToggle';
import Icon from '../../../../components/Icon/Icon';
import { LINK_CENTRAL_HELP } from '../../../../data/Constants';
import {
  ISubjectsAnnouncementsItem,
  useManagerPreferencesContext
} from '../../../../data/ManagerPreferences/ManagerPreferences';
import { useAuthContext } from '../../../../hooks/AuthContext';
import { useControllerFlowStepsContext } from '../../../../hooks/ControllerFlowStepsContext';
import { useControlNavBarContext } from '../../../../hooks/ControlNavBar';
import { getPreferencesAnnouncements } from '../../../../services/user';

export default function CardSubjectCommunicate() {
  const {
    checkTypeSubjectsAnnouncements,
    setcheckTypeSubjectsAnnouncements,
    copySubjectsAnnouncements,
    sizeView,
    isFormEqual,
    setshowModalPendingDataToSave,
    openModalPendingDataToSave
  } = useManagerPreferencesContext();
  const navigate = useNavigate();
  const { checkAuth } = useAuthContext();
  const { showErrorMessageServer } = useControllerFlowStepsContext();
  const { setShowButton, setGoBackNavigate } = useControlNavBarContext();

  function interceptorGoBack() {
    console.log(isFormEqual);
    if (isFormEqual.equal) {
      navigate('/menu-de-conta/preferencias-de-comunicacao', {
        state: 'refresh'
      });
    } else {
      setshowModalPendingDataToSave(true);
      openModalPendingDataToSave();
    }
  }

  function configGoBack() {
    setGoBackNavigate({
      path: '/menu-de-conta/preferencias-de-comunicacao',
      param: { state: 'refresh' },
      condition: interceptorGoBack
    });
  }

  useEffect(() => {
    configGoBack();
  }, [isFormEqual.equal]);

  function redirectCentralHelp() {
    window.open(LINK_CENTRAL_HELP, '_blank');
  }

  useEffect(() => {
    preferencesAnnouncements();
    if (sizeView[0] <= 576) {
      setShowButton(true);
      configGoBack();
    }
  }, []);

  async function preferencesAnnouncements() {
    try {
      const response = await getPreferencesAnnouncements(
        checkAuth().user.email
      );
      setStatusAnnouncements(response);
    } catch (error) {
      showErrorMessageServer();
    }
  }

  function setStatusAnnouncements(data: any) {
    const updateAnnouncements: Array<ISubjectsAnnouncementsItem> = [];
    checkTypeSubjectsAnnouncements.map((subject: any) => {
      for (const [key, value] of Object.entries(data)) {
        if (key === subject.value) {
          subject.active = value;
          updateAnnouncements.push(subject);
        }
      }
    });
    copySubjectsAnnouncements(updateAnnouncements);
    setcheckTypeSubjectsAnnouncements(updateAnnouncements);
  }

  function changeStatusSubjectAnnouncements(status: boolean, index: number) {
    setcheckTypeSubjectsAnnouncements((state) => {
      state[index].active = status;
      return [...state];
    });
  }

  function mountOptionsSubject() {
    return checkTypeSubjectsAnnouncements.map((subject, index) => {
      return (
        <div
          key={index}
          className="flex width-full border-box pl-24 pr-24 pt-8 pb-8"
          style={{ borderBottom: '1px solid #D2D2D2' }}
        >
          <span className="lg color-text-default">{subject.title}</span>

          <div className="ml-auto">
            <span className="md mr-8 color-text-default">
              {subject.active ? 'ativado' : 'desativado'}
            </span>
            <FormFieldToggle
              value={subject.value}
              checked={subject.active}
              callbackChange={(status: boolean) =>
                changeStatusSubjectAnnouncements(status, index)
              }
            />
          </div>
        </div>
      );
    });
  }

  return (
    <Card
      width="100%"
      paddingHorizontal="24"
      paddingVertical="24"
      id="subjectCommunicate"
    >
      <div className="width-full flex flex-column">
        <div className="width-full">
          <h2 className="color-text-default mb-16">
            gerenciar assuntos dos comunicados
          </h2>
          <span className="lg color-text-default">
            Escolha os assuntos dos comunicados que você recebe.
          </span>
          <div className="width-full mt-13 flex align-items-center">
            <Icon name="interrogation" className="account-data-info-icon" />
            <span className="sm ml-8 color-text-default">
              Tem dúvidas sobre algum assunto da lista?{' '}
              <span onClick={redirectCentralHelp} className="link">
                Clique aqui
              </span>{' '}
              para ver os detalhes.
            </span>
          </div>

          <div className="mt-37">{mountOptionsSubject()}</div>
        </div>
      </div>
    </Card>
  );
}
