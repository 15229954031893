export function dateMask(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 10;
  let value = e.currentTarget.value;
  value = value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1');

  e.currentTarget.value = value;

  return e;
}

export function cpfMask(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 14;
  let value = e.currentTarget.value;
  value = value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2');

  e.currentTarget.value = value;

  return e;
}

export function cepMask(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 9;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2');

  e.currentTarget.value = value;

  return e;
}

export function mobilePhoneMask(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 15;
  let value = e.currentTarget.value;
  value = value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2');

  e.currentTarget.value = value;

  return e;
}

export function maskClearSpecialCharacters(
  e: React.FormEvent<HTMLInputElement>
) {
  let value = e.currentTarget.value;
  value = value.replace(/[^a-zA-ZÀ-ú ]/g, '');

  e.currentTarget.value = value;

  return e;
}

export function clearSpaces(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.value = e.currentTarget.value.trim();
}

export function passwordMask(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.value = e.currentTarget.value?.replace(' ', '');
}
