import * as yup from 'yup';
import {
  schemaBoolRequiredSocialName,
  schemaEmailRequired,
  schemaFullName,
  schemaSocialName,
  schemaStringDateRequired,
  schemaStringPhoneRequired
} from '../SchemaFieldsRequired';

export interface FormSignInValues {
  email: string;
  password: string;
  checkPassword: string;
  acceptTermsOfUse: boolean;
  userName: string;
  socialName: string;
  hasSocialName: boolean;
  birthDate: string;
  cellPhoneNumber?: string;
}

export function createFormSign() {
  const objectFiels = {
    email: schemaEmailRequired,
    password: yup.string(),
    checkPassword: yup.string(),
    acceptTermsOfUse: yup.bool(),
    userName: schemaFullName,
    socialName: schemaSocialName,
    hasSocialName: schemaBoolRequiredSocialName,
    birthDate: schemaStringDateRequired,
    cellPhoneNumber: schemaStringPhoneRequired
  };

  return yup.object(objectFiels);
}
