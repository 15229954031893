import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/global.scss';
import AuthProvider from './hooks/AuthContext';
import ControlNavBarProvider from './hooks/ControlNavBar';
import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet';
import { APP_ENV } from './data/Constants';

const ID_GOOGLE_TAG_MANAGER = process.env
  .REACT_APP_ID_GOOGLE_TAG_MANAGER as string;

Sentry.init({
  environment: APP_ENV,
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        'https://auth.sbt.com.br/',
        /^https:\/\/yourserver\.io\/api/
      ]
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}`}
          async={true}
          defer={false}
        ></script>
        {/* Google Tag Manager */}
        <script async={true} defer={false}>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${ID_GOOGLE_TAG_MANAGER}')`}
        </script>
        {/* End Google Tag Manager */}
      </Helmet>
      <ControlNavBarProvider>
        <App />
      </ControlNavBarProvider>
    </AuthProvider>
  </React.StrictMode>
);
