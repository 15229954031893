import { ReactNode } from 'react';
interface StepProps {
  id: number;
  currentStep: number;
  children?: ReactNode;
}

export default function Step({ id, currentStep, children }: StepProps) {
  const showStep = id === currentStep;

  return showStep ? <div>{children}</div> : null;
}
