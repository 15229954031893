import './ButtonLabel.scss';
import Loading from '../Loading/Loading';
import { ReactNode } from 'react';
interface ButtonLabelProps {
  loading?: boolean;
  children: ReactNode;
}

export default function ButtonLabel(props: ButtonLabelProps) {
  const { loading } = props;
  if (loading) {
    return <Loading color="white" />;
  }
  return <span>{props.children}</span>;
}
