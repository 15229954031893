import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import ButtonLabel from '../../components/Button/ButtonLabel';
import Date from '../../components/Date/Date';
import LogoText from '../../components/LogoText/LogoText';
import SocialName from '../../components/SocialName/SocialName';
import { checkSocialName } from '../../utils/GenericFunctions';
import { useControllerFlowStepsContext } from '../../hooks/ControllerFlowStepsContext';
import Terms from '../Terms';

interface PersonalDataProps {
  allowSkipStep?: boolean;
  previousPath?: number | null;
  nextPath?: number | null;
  handleChangeStep(e: number): void;
  context: any;
}

export default function PersonalData({
  nextPath,
  handleChangeStep,
  previousPath,
  context
}: PersonalDataProps) {
  const navigate = useNavigate();

  const {
    register,
    errors,
    originDomainForm,
    getValues,
    setValue,
    trigger,
    touchedFields,
    watch
  } = useContext<any>(context);
  const { socialData } = useControllerFlowStepsContext();
  const [acceptTermsOfUse, setAcceptTermsOfUse] = useState(false);
  const [fieldSocialName, setFieldSocialName] = useState('');
  const [fieldHasSocialName, setFieldHasSocialName] = useState(false);
  const url = `/${originDomainForm}/complemento-dados-pessoais`;

  useEffect(() => {
    navigate(url);

    window.addEventListener('popstate', function (event) {
      controlBackPage();
    });
  }, [navigate]);

  window.onpopstate = () => {
    controlBackPage();
  };

  function controlBackPage() {
    if (previousPath == 0 || previousPath) {
      handleChangeStep(previousPath);
    } else window.location.reload();
  }

  useEffect(() => {
    const subscription = watch((data: any) => {
      setAcceptTermsOfUse(data.acceptTermsOfUse);
      setFieldSocialName(data.socialName);
      setFieldHasSocialName(data.hasSocialName);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (socialData.userName) {
      setValue('userName', socialData.userName, { shouldTouch: true });
      trigger(['userName']);
      setValue('password', '');
    }
  }, []);

  async function checkValidateFields() {
    const validateFullName = await trigger(['userName']);
    const validateBirthDate = await trigger(['birthDate']);
    const validateSocialName = getValues('hasSocialName')
      ? await trigger(['socialName'])
      : true;

    return new Promise((resolve) => {
      const fixedFieldsValidated = validateFullName && validateBirthDate;
      if (!validateSocialName) {
        resolve(false);
      }
      resolve(fixedFieldsValidated);
    });
  }

  function nextStep() {
    checkValidateFields().then((response) => {
      if (response && nextPath) {
        handleChangeStep(nextPath);
      }
    });
  }

  return (
    <div className="flex flex-column">
      <LogoText />
      <h2 className="color-text-default">criar conta</h2>
      <span className="md mt-4 color-text-default">
        Desfrute o melhor do SBT com uma única conta! <br />
        Complete os seus dados.
      </span>

      <div className="flex flex-column mt-16">
        <SocialName
          register={register}
          errors={errors}
          touchedFields={touchedFields}
          setValue={setValue}
        />
        <Date
          register={register}
          errors={errors}
          touchedFields={touchedFields}
        />

        <Terms
          nextPath={nextPath}
          handleChangeStep={handleChangeStep}
          previousPath={previousPath}
          context={context}
        />

        <Button
          full
          onClick={nextStep}
          disabled={
            !!errors?.userName ||
            !getValues('userName') ||
            (fieldHasSocialName && checkSocialName(fieldSocialName)) ||
            !!errors?.birthDate ||
            !touchedFields?.birthDate ||
            !acceptTermsOfUse
          }
        >
          <ButtonLabel>avançar</ButtonLabel>
        </Button>
      </div>
    </div>
  );
}
