import './CTVSuccess.scss';
import Icon from '../../../components/Icon/Icon';
import { useEffect, useState } from 'react';
import { useControllerFlowStepsContext } from '../../../hooks/ControllerFlowStepsContext';
import { useNavigate } from 'react-router-dom';
import {
  checkCTVRecieveResponse,
  saveCTVCredentials
} from '../../../services/ctv';
import Loading from '../../../components/Loading/Loading';
import CTVContainer from '../../../components/CTVContainer';

const INTERVAL_IN_SECONDS = 5;
const MAX_ATTEMPTS = 8;

let savedCredentials = false;
let currentAttempts = 0;

export default function CTVSuccess() {
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { ctvCode } = useControllerFlowStepsContext();

  const navigate = useNavigate();

  async function checkCTVResponse(interval: NodeJS.Timer) {
    if (currentAttempts >= MAX_ATTEMPTS) {
      clearInterval(interval);
      setSuccess(false);
      setIsLoading(false);
      return;
    }

    currentAttempts++;
    const response = await checkCTVRecieveResponse(ctvCode || '');

    if (response?.used) {
      clearInterval(interval);
      setSuccess(true);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!ctvCode) navigate('/');

    async function handleSaveCtvCredentials() {
      if (!ctvCode) return;

      try {
        if (!savedCredentials) {
          savedCredentials = true;
          await saveCTVCredentials(ctvCode);
        }

        const interval = setInterval(() => {
          checkCTVResponse(interval);
        }, INTERVAL_IN_SECONDS * 1000);

        return () => clearInterval(interval);
      } catch (e) {
        setSuccess(false);
        setIsLoading(false);
        console.error('error CTV', e);
      }
    }

    handleSaveCtvCredentials();
  }, []);

  return (
    <CTVContainer>
      <div className="ctv-card">
        {isLoading ? (
          <>
            <Loading />
            <h2 className="ctv-title mti-16 mbi-10">
              Aguardando
              <br /> confirmação da TV
            </h2>
            <p>
              Sua TV será configurada automaticamente. Isso pode demorar alguns
              minutos.
            </p>
          </>
        ) : success ? (
          <>
            <Icon name="successOutlined" />
            <h2 className="ctv-title mti-24">Tudo certo!</h2>
            <p>
              Sua TV será configurada automaticamente. Você pode fechar essa
              janela.
            </p>
          </>
        ) : (
          <>
            <Icon name="errorOutlined" />
            <h2 className="ctv-title ctv-title-error mti-24">
              Algo deu errado
            </h2>
            <p>
              Algo deu errado e sua TV não foi configurada. Tente novamente.
            </p>
          </>
        )}
      </div>
    </CTVContainer>
  );
}
