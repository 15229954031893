import { useEffect } from 'react';

import { useControllerSocialMediaContext } from '../../hooks/ControllerSocialMedia';

import { initFB } from '../../services/socialNetWorkController';
import Icon from '../Icon/Icon';
import './SocialMedia.scss';
import {
  handleOAuthRedirectURI,
  shouldOpenOAuthWithRedirect
} from '../../utils/GenericFunctions';
import { setCookie } from '../../services/cookieController';
import { useAuthContext } from '../../hooks/AuthContext';

export default function ButtonFace() {
  const { loginFace } = useControllerSocialMediaContext();
  const { redirectURL } = useAuthContext();

  useEffect(() => {
    initFB();
  }, []);

  return (
    <button
      data-testid="btn-face"
      type="button"
      onClick={() => {
        if (shouldOpenOAuthWithRedirect(redirectURL)) {
          if (redirectURL) setCookie('redirect', redirectURL);
          window.open(
            `https://www.facebook.com/v17.0/dialog/oauth?client_id=${
              process.env.REACT_APP_APPID_FACE
            }&redirect_uri=${handleOAuthRedirectURI(
              'facebook'
            )}&scope=email,public_profile&return_scopes=true&fields=name,email&response_type=token&state=421421`,
            '_self'
          );
        } else loginFace(false);
      }}
    >
      <Icon name="logoFace" />
    </button>
  );
}
