import { ReactNode } from 'react';

import './CTVContainer.scss';

import LogoText from '../LogoText/LogoText';
import { LINK_HELP_PAGE } from '../../data/Constants';

interface CTVContainerProps {
  children: ReactNode;
}

export default function CTVContainer({ children }: CTVContainerProps) {
  function handleHelpClick() {
    window.open(LINK_HELP_PAGE, '_blank');
  }

  return (
    <div className="ctv-container">
      <div className="logo-container">
        <LogoText />
      </div>

      {children}

      <footer className="footer-container">
        <span className="copyright">
          SBT Copyright © 2024 Todos os direitos reservados.
        </span>

        <div className="help-container" onClick={handleHelpClick}>
          <span>Precisa de ajuda?</span>
          {/* <Icon name="infoOutlined" /> */}
        </div>
      </footer>
    </div>
  );
}
