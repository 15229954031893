import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/AuthContext';
import Button from '../Button/Button';
import ButtonLabel from '../Button/ButtonLabel';
import Icon from '../Icon/Icon';
import LogoText from '../LogoText/LogoText';
import { useControllerFlowStepsContext } from '../../hooks/ControllerFlowStepsContext';
import { CTV_SUCCESS_PAGE_PATH } from '../../data/Constants';
interface SuccessProps {
  children: React.ReactNode;
  context: any;
}

export default function Success({ children, context }: SuccessProps) {
  const { originDomainForm } = useContext<any>(context);
  const { redirectToSite } = useAuthContext();
  const url = `/${originDomainForm}/cadastro-concluido`;
  const navigate = useNavigate();
  const { ctvCode } = useControllerFlowStepsContext();

  useEffect(() => {
    navigate(url, { replace: true });
  }, [navigate]);

  function redirect() {
    if (ctvCode) return navigate(CTV_SUCCESS_PAGE_PATH);
    redirectToSite();
  }
  return (
    <div className="flex flex-column">
      <LogoText />

      <div className="flex flex-column mt-16 success-card">
        <Icon name="success" />
        <h2 className="mti-18 mbi-16">tudo certo!</h2>

        {children}

        <Button className="mt-8" full onClick={redirect}>
          <ButtonLabel>concluir</ButtonLabel>
        </Button>
      </div>
    </div>
  );
}
