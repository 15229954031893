import Container from '../../components/Container/Container';
import LogoText from '../../components/LogoText/LogoText';
import Card from '../../components/Card/Card';
import { LINK_CENTRAL_HELP } from '../../data/Constants';

function Error() {
  function redirectCentralHelp() {
    window.open(LINK_CENTRAL_HELP, '_blank');
  }

  return (
    <Container>
      <Card width="400px" minHeight="550px" center>
        <div className="flex flex-column">
          <LogoText />
          <h2 className="color-text-default mt-24">
            :(
            <br /> Desculpe, não conseguimos encontrar essa página
          </h2>
          <span className="md mt-16 color-text-default">
            Verifique se o endereço foi digitado corretamente e tente novamente.{' '}
            <br />
            Caso você ainda não consiga encontrar o que procura, acesse nossa
            <a
              onClick={redirectCentralHelp}
              className="strong link-intern-text ml-3 text-purple"
              rel="noreferrer"
            >
              central de ajuda
            </a>
          </span>
        </div>
      </Card>
    </Container>
  );
}

export default Error;
