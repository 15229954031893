interface FormFieldToggle {
  value: string;
  checked: boolean;
  callbackChange: (value: boolean) => void;
}

export default function FormFieldToggle({
  value,
  checked,
  callbackChange
}: FormFieldToggle) {
  function changeToggle(e: React.FormEvent<HTMLInputElement>) {
    callbackChange(e.currentTarget.checked);
  }

  return (
    <label className="switch-toggle">
      <input
        type="checkbox"
        value={value}
        checked={checked}
        onChange={changeToggle}
      />
      <span className="slider round"></span>
    </label>
  );
}

// ex: Implementation
{
  /*
      <FormFieldToggle />
  */
}
