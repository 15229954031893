import { createContext, useContext, useRef, useState } from 'react';
import {
  FieldErrorsImpl,
  useForm,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormTrigger,
  UseFormUnregister,
  UseFormResetField,
  UseFormWatch,
  UseFormGetFieldState,
  UseFormClearErrors
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createForm, FormUpdateUserDataValues } from './FormUpdateUserData';
import { Outlet } from 'react-router-dom';
import { ModalHandles } from '../../components/Modal/Modal';
import { UseWindowSize } from '../../hooks/ResizeWindow';

export interface UpdateUserDataContextData {
  register: UseFormRegister<FormUpdateUserDataValues>;
  handleSubmit: UseFormHandleSubmit<FormUpdateUserDataValues>;
  errors: Partial<FieldErrorsImpl<FormUpdateUserDataValues>>;
  touchedFields: Partial<Readonly<any>>;
  trigger: UseFormTrigger<FormUpdateUserDataValues>;
  origin: string | null;
  submitForm(payload: any, callBack: any): void;
  watch: UseFormWatch<FormUpdateUserDataValues>;
  getValues: UseFormGetValues<FormUpdateUserDataValues>;
  setValue: UseFormSetValue<FormUpdateUserDataValues>;
  setFocus: UseFormSetFocus<FormUpdateUserDataValues>;
  setError: UseFormSetError<FormUpdateUserDataValues> | any;
  isValid: boolean;
  unregister: UseFormUnregister<FormUpdateUserDataValues>;
  resetField: UseFormResetField<FormUpdateUserDataValues>;
  getFieldState: UseFormGetFieldState<FormUpdateUserDataValues>;
  updatedPhone: boolean;
  setUpdatePhone: React.Dispatch<React.SetStateAction<boolean>>;
  updatedData: boolean;
  setUpdatedData: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibleFieldGenrer: React.Dispatch<React.SetStateAction<boolean>>;
  visibleFieldGenrer: boolean;
  setHasCpf: React.Dispatch<React.SetStateAction<boolean>>;
  hasCpf: boolean;
  setNoNumber: React.Dispatch<React.SetStateAction<boolean>>;
  noNumber: boolean;
  setUserHasPassowrd: React.Dispatch<React.SetStateAction<boolean>>;
  userHasPassowrd: boolean;
  openModalDeletePhone(): void;
  modalRef: React.RefObject<ModalHandles>;
  sizeView: any;
  setSelectedLink: React.Dispatch<React.SetStateAction<number>>;
  selectedLink: number;
  setFormEqual: React.Dispatch<any>;
  isFormEqual: any;
  setshowModalPendingDataToSave: React.Dispatch<React.SetStateAction<boolean>>;
  showModalPendingDataToSave: boolean;
  openModalPendingDataToSave(): void;
  clearErrors: UseFormClearErrors<FormUpdateUserDataValues>;
}

export const UpdateUserDataContext = createContext(
  {} as UpdateUserDataContextData
);

const UpdateUserDataProvider = () => {
  const formFields = createForm();
  const {
    register,
    unregister,
    trigger,
    watch,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    setError,
    resetField,
    getFieldState,
    clearErrors,
    formState: { errors, touchedFields, isValid }
  } = useForm<FormUpdateUserDataValues>({
    mode: 'onChange',
    defaultValues: {
      isSocial: false
    },
    resolver: yupResolver(formFields, { abortEarly: false })
  });
  const [updatedPhone, setUpdatePhone] = useState(false);
  const [updatedData, setUpdatedData] = useState(false);
  const [visibleFieldGenrer, setVisibleFieldGenrer] = useState(false);
  const [hasCpf, setHasCpf] = useState(true);
  const [noNumber, setNoNumber] = useState(false);
  const [userHasPassowrd, setUserHasPassowrd] = useState(false);
  const sizeView: any = UseWindowSize();
  const [selectedLink, setSelectedLink] = useState(0);
  const [showModalPendingDataToSave, setshowModalPendingDataToSave] =
    useState(false);

  const [isFormEqual, setFormEqual] = useState({
    equal: true,
    fieldsNotEqual: []
  } as any);

  async function submitForm(formValues: any, callBack: any) {
    try {
      callBack();
    } catch (error) {
      console.log('submitform error', error);
    }
  }

  const modalRef = useRef<ModalHandles>(null);
  function openModalDeletePhone() {
    modalRef.current?.openModal();
  }

  function openModalPendingDataToSave() {
    modalRef.current?.openModal();
  }

  return (
    <UpdateUserDataContext.Provider
      value={{
        origin,
        register,
        unregister,
        handleSubmit,
        errors,
        submitForm,
        touchedFields,
        trigger,
        getValues,
        setValue,
        setFocus,
        watch,
        setError,
        isValid,
        resetField,
        getFieldState,
        updatedPhone,
        setUpdatePhone,
        updatedData,
        setUpdatedData,
        visibleFieldGenrer,
        setVisibleFieldGenrer,
        hasCpf,
        setHasCpf,
        noNumber,
        setNoNumber,
        userHasPassowrd,
        setUserHasPassowrd,
        modalRef,
        openModalDeletePhone,
        sizeView,
        selectedLink,
        setSelectedLink,
        isFormEqual,
        setFormEqual,
        showModalPendingDataToSave,
        setshowModalPendingDataToSave,
        openModalPendingDataToSave,
        clearErrors
      }}
    >
      <Outlet />
    </UpdateUserDataContext.Provider>
  );
};
export const useUpdateUserDataContext = () =>
  useContext<UpdateUserDataContextData>(UpdateUserDataContext);
export default UpdateUserDataProvider;
