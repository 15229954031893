import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import ButtonLabel from '../../../../components/Button/ButtonLabel';
import { useUpdateUserDataContext } from '../../../../data/UpdateUserData/UpdateUserDataContext';
import { useAuthContext } from '../../../../hooks/AuthContext';
import { useControllerFlowStepsContext } from '../../../../hooks/ControllerFlowStepsContext';
import { updateTelephone } from '../../../../services/user';
import { useState } from 'react';
import { useControlNavBarContext } from '../../../../hooks/ControlNavBar';

export default function RegisterConfirmPhone() {
  const { user } = useAuthContext();
  const { getValues, updatedPhone, setUpdatedData } =
    useUpdateUserDataContext();
  const { setAlertBox } = useControllerFlowStepsContext();
  const { setShowButton, setGoBackNavigate } = useControlNavBarContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShowButton(true);
    setGoBackNavigate({
      path: '',
      param: {}
    });
  }, []);

  useEffect(() => {
    navigate('../confirmar-telefone', { relative: 'path' });
  }, [navigate]);

  async function confirm() {
    setLoading(true);
    const phone = getValues('cellPhoneNumber');
    const formatValue = phone?.replace(/\D/g, '');
    try {
      await updateTelephone(user.email, formatValue);
      setUpdatedData(true);
      setAlertBox({
        message: `seu número de celular foi ${
          updatedPhone ? 'atualizado' : 'adicionado'
        }`,
        error: false
      });
      navigate('/menu-de-conta/atualizar-dados', { replace: true });
    } catch (error) {
      setLoading(false);
      console.error(error);
      setAlertBox({
        message: `seu número de celular não foi ${
          updatedPhone ? 'atualizado' : 'adicionado'
        }`,
        error: true
      });
    }
  }

  function navigateToMenu() {
    navigate('/menu-de-conta', { replace: true });
  }

  return (
    <>
      <div className="flex flex-column">
        <h2 className="color-text-default">
          {updatedPhone ? 'alterar celular' : 'incluir celular'}
        </h2>
        <span className="md mt-8 mb-16">
          O número de celular{' '}
          {updatedPhone ? 'será alterado para' : 'adicionado será'}
        </span>
        <h2 className="color-text-default">
          +55 {getValues('cellPhoneNumber')}
        </h2>

        <div className="flex flex-column mt-24">
          <Button className="mbi-0" full onClick={confirm} disabled={loading}>
            <ButtonLabel loading={loading}>confirmar</ButtonLabel>
          </Button>
          <Button
            className="mt-16 mbi-0"
            customColors="btn-white"
            full
            loading={loading}
            onClick={navigateToMenu}
          >
            <ButtonLabel>cancelar</ButtonLabel>
          </Button>
        </div>
      </div>
    </>
  );
}
