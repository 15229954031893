import axios from 'axios';

export async function getFacebookUserInformation(accessToken: string) {
  try {
    const res = await axios.get('https://graph.facebook.com/v19.0/me', {
      params: {
        access_token: accessToken,
        fields: 'id,name',
        format: 'json',
        method: 'get',
        transport: 'cors'
      }
    });

    return res?.data;
  } catch (e) {
    return null;
  }
}
