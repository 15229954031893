import { useEffect, useState } from 'react';
import LogoText from '../../components/LogoText/LogoText';
import { getTermsAndConditions } from '../../services/queryController';
import './Terms.scss';
import Loading from '../../components/Loading/Loading';

export enum TERMS_POLICY_TYPES {
  TERMS = 'termos-de-uso',
  POLICY = 'politica-de-privacidade'
}

interface Props {
  type: TERMS_POLICY_TYPES;
}

export default function TermsAndPolicy({ type }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [text, setText] = useState<string>('');

  useEffect(() => {
    const fetchTerms = async () => {
      const data = await getTermsAndConditions();
      if (type === TERMS_POLICY_TYPES.POLICY) setText(data.privacyPolicy);
      else if (type === TERMS_POLICY_TYPES.TERMS) setText(data.terms);
      setIsLoading(false);
    };
    fetchTerms();
  }, []);

  return (
    <>
      <div className="terms-policy-container">
        <div className="title-container">
          <LogoText />
          <div className="terms-policy-container-title">
            <h1>
              {type === TERMS_POLICY_TYPES.POLICY
                ? 'política de privacidade'
                : 'termos de uso'}
            </h1>
          </div>
        </div>
        <div className="terms-policy-container-description">
          <p
            className="md color-text-default"
            style={
              type === TERMS_POLICY_TYPES.POLICY
                ? { whiteSpace: 'break-spaces' }
                : {}
            }
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
      </div>
      {isLoading && (
        <div className="loading-full-screen">
          <Loading />
        </div>
      )}
    </>
  );
}
