import { ReactNode } from 'react';
import Card from './Card';
import CardDescription from './CardDescription';
import './CardIcon.scss';
import CardTitle from './CardTitle';

interface CardIconProps {
  Icon?: any;
  title: string;
  full?: boolean;
  disabled?: boolean;
  description?: string;
  children?: ReactNode;
  onClick?: () => void;
}

export default function CardIcon({
  title,
  description,
  disabled,
  onClick,
  full,
  children
}: CardIconProps) {
  return (
    <Card full={full} disabled={disabled}>
      <div className="card-icon-container" onClick={onClick}>
        <div className="card-icon-text">
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </div>
        <div className={'card-icon ' + (disabled ? 'card-icon-disabled' : '')}>
          {children}
        </div>
      </div>
    </Card>
  );
}

// Ex: implementation
{
  /* <CardIcon
      full
      title="Titulo do card"
      description="texto de apoio com explicação para o usuário"
      icon={DeviceIcon} />
*/
}
