import { debounceInput } from '../../utils/GenericFunctions';
import FormField from './FormField';
import './FormField.scss';

interface FormFieldSelectAutoCompleteProps {
  items?: any;
  name?: string;
  label?: string;
  placeholder?: string;
  touchedFields?: any;
  error?: any;
  register?: any;
  disabled?: boolean;
  selectValue: (value: any) => any;
  dispatchFunction: (value: any) => any;
}

export default function FormFieldSelectAutoComplete({
  items = [],
  name,
  label,
  register,
  placeholder,
  touchedFields,
  error,
  disabled,
  selectValue,
  dispatchFunction
}: FormFieldSelectAutoCompleteProps) {
  function onChange(e: React.FormEvent<HTMLInputElement>) {
    if (e.currentTarget.value.length) {
      debounceInput(dispatchFunction, 700);
    } else {
      selectValue(null);
    }
  }
  function mountValues() {
    return items.map((item: any, index: number) => {
      return (
        <span onClick={() => selectValue(item)} key={index}>
          {item.value}
        </span>
      );
    });
  }

  return (
    <div className="select-container">
      <FormField
        label={label}
        disabled={disabled}
        placeholder={placeholder}
        register={register}
        touchedFields={touchedFields}
        name={name}
        error={error}
        onInput={onChange}
      />
      {items.length ? (
        <div className={'form-select-auto-complete'}>{mountValues()}</div>
      ) : null}
    </div>
  );
}
