import { useEffect, useRef } from 'react';
import { useAuthContext } from '../../hooks/AuthContext';
import { useControllerFlowStepsContext } from '../../hooks/ControllerFlowStepsContext';
import LoadingFullScreen, {
  LoadingFullScreenHandles
} from '../LoadingFullScreen/LoadingFullScreen';
import BackToAppFullScreen, {
  BackToAppFullScreenHandles
} from '../BackToAppFullScreen/BackToAppFullScreen';

import './Container.scss';

interface ContainerProps {
  className?: string;
  children: React.ReactNode;
}
export default function Container(props: ContainerProps) {
  const { openSession, openGoBackToApp } = useAuthContext();
  const { loadingFullScreen } = useControllerFlowStepsContext();
  const refLoadingFullScreen = useRef<LoadingFullScreenHandles>(null);
  const refGoBackToAppFullScreen = useRef<BackToAppFullScreenHandles>(null);

  useEffect(() => {
    if (loadingFullScreen) {
      refLoadingFullScreen?.current?.openLoadingFullScreen();
    } else {
      refLoadingFullScreen?.current?.closeLoadingFullScreen();
    }
  }, [loadingFullScreen]);

  useEffect(() => {
    if (openGoBackToApp) {
      refGoBackToAppFullScreen?.current?.openBackToAppFullScreen();
    } else {
      refGoBackToAppFullScreen?.current?.closeBackToAppFullScreen();
    }
  }, [openGoBackToApp]);

  return (
    <>
      <div
        className={`container flex ${props.className || ''} ${
          openSession ? 'container__opened' : ''
        }`}
      >
        {props.children}
      </div>
      <LoadingFullScreen ref={refLoadingFullScreen} />
      <BackToAppFullScreen ref={refGoBackToAppFullScreen} />
    </>
  );
}
