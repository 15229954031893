import './AlertCard.scss';
import { ReactComponent as TrianguleWarning } from '../../assets/icons/triangle-exclamation.svg';

interface AlertCardProps {
  text: string;
}

export default function AlertCard({ text }: AlertCardProps) {
  return (
    <div className="alert-card mt-8">
      <div className="alert-card__icon">
        <TrianguleWarning />
        <span>ops!</span>
      </div>
      <div className="alert-card__text">
        <span>{text}</span>
      </div>
    </div>
  );
}

// Ex: implementation
{
  /* <AlertCard
      text="lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
      blandit, dui at cursus vulputate, purus risus place. lorem risus plac"/>;
  */
}
