import jsonWebTokenService from 'jsonwebtoken';
import { POST } from './api';
import { ErrorMessages, IErrorMessage } from '../data/ErrorMessages';
import { SentryContextsEnum } from '../utils/Sentry';
import { ISendCodeEmailRequest } from '../interfaces/email/ISendEmail';
import { SendCodeToEmailEventsEnum } from '../enums/SendCodeToEmailEventsEnum';
const ACCOUNT_SERVICE = process.env.REACT_APP_BASE_URL_ACCOUNT_SERVICE;

async function sendTokenToEmail(
  email: string,
  emailEvent: SendCodeToEmailEventsEnum,
  customErrorMessage: IErrorMessage
) {
  const payload: ISendCodeEmailRequest = {
    email,
    emailEvent
  };

  return await POST(
    `${ACCOUNT_SERVICE}/api/v2/email/code`,
    payload,
    customErrorMessage
  );
}

export async function createTokenRecoveryPassword(
  email: string,
  context?: SentryContextsEnum
) {
  const errorMessage = ErrorMessages.SEND_EMAIL_RECOVERY_PASSWORD;
  if (context) errorMessage.context = context;

  const response = await POST(
    `${ACCOUNT_SERVICE}/api/v1/email/code/pass/${email}`,
    {},
    errorMessage
  );
  return response.data;
}

export async function createTokenValidateEmail(email: string) {
  const response = await sendTokenToEmail(
    email,
    SendCodeToEmailEventsEnum.CONFIRM_EMAIL,
    ErrorMessages.SEND_EMAIL_VALIDATE_ACCOUNT
  );
  return response.data;
}

export async function validateToken(
  key: string,
  token: string,
  errorMessage?: IErrorMessage
) {
  const response = await POST(
    `${ACCOUNT_SERVICE}/api/v1/token`,
    {
      key,
      token
    },
    errorMessage
  );
  return response.data;
}

export function readJwt(jwt: string) {
  return jsonWebTokenService.decode(jwt);
}

export async function createTokenValidatePhone(
  phoneNumber: string,
  context?: SentryContextsEnum
) {
  const errorMessage = ErrorMessages.SEND_SMS_VALIDATE_PHONE;
  if (context) errorMessage.context = context;

  const response = await POST(
    `${ACCOUNT_SERVICE}/api/v1/phone/code/${phoneNumber}`,
    {},
    errorMessage
  );
  return response.data;
}
