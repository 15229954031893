import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import ButtonLabel from '../../components/Button/ButtonLabel';
import FormField from '../../components/FormField/FormField';
import LogoText from '../../components/LogoText/LogoText';
import { mobilePhoneMask } from '../../utils/Masks';
import { checkExistNumberPhone } from '../../services/queryController';
import { useControllerFlowStepsContext } from '../../hooks/ControllerFlowStepsContext';
import { ResponseTokenSocial } from '../../model/Models';
import { sendSocialData } from '../../services/socialNetWorkController';
import { isSignUp } from '../../utils/GenericFunctions';
import { SentryContextsEnum } from '../../utils/Sentry';

interface PhoneNumberProps {
  allowSkipStep?: boolean;
  updateData?: boolean;
  previousPath?: number | null;
  nextPath?: number | null;
  handleChangeStep(e: number): void;
  context: any;
  viewSuccess?: any;
}

export default function PhoneNumber({
  allowSkipStep,
  nextPath,
  handleChangeStep,
  previousPath,
  context
}: PhoneNumberProps) {
  const {
    socialWithoutEmail,
    socialData,
    providerSocial,
    tokenSocial,
    notCreateAccount,
    showErrorMessageServer,
    setNotCreateAccount
  } = useControllerFlowStepsContext();
  const {
    register,
    watch,
    errors,
    setError,
    getValues,
    originDomainForm,
    setValue,
    touchedFields,
    handleSubmit,
    submitForm
  } = useContext<any>(context);
  const url = `/${originDomainForm}/cadastro-telefone`;
  const navigate = useNavigate();
  const [fieldValidate, setValueValidateField] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setNotCreateAccount(false);
  }, []);

  useEffect(() => {
    navigate(url);
  }, []);

  window.onpopstate = () => {
    controlBackPage();
  };

  function controlBackPage() {
    if (previousPath == 0 || previousPath) {
      handleChangeStep(previousPath);
    }
  }

  useEffect(() => {
    const subscription = watch((data: any) => {
      setValueValidateField(data.cellPhoneNumber);
    });
    return () => subscription?.unsubscribe();
  }, [watch]);

  function changeToViewToken() {
    checkExistPhone();
  }

  async function createAccount() {
    if (providerSocial) {
      setValue('isSocial', true);
      setValue('provider', providerSocial);
      if (socialData.email) setValue('email', socialData.email);

      await handleSubmit(
        async (data: any) => await submitForm(data, nextStep, providerSocial)
      )();
    } else {
      await handleSubmit(
        async (data: any) => await submitForm(data, nextStep)
      )();
    }
  }

  async function checkExistPhone() {
    let numberPhone = getValues('cellPhoneNumber');
    numberPhone = numberPhone?.replace(/\D/g, '')?.replace(/\s/g, '');
    setLoading(true);
    try {
      const response: any = await checkExistNumberPhone(
        numberPhone,
        providerSocial ? SentryContextsEnum.SOCIAL_SIGNUP : undefined
      );
      if (response?.exist) {
        setError('cellPhoneNumber', {
          message: 'número de celular já vinculado a uma conta'
        });
      } else {
        if (notCreateAccount && !socialWithoutEmail) {
          nextStep();
        } else if (socialWithoutEmail) {
          await sendSocialToken();
        } else {
          await createAccount();
        }
      }
    } catch (error) {
      console.log(error);
      showErrorMessageServer();
    } finally {
      setLoading(false);
    }
  }

  async function sendSocialToken() {
    const token = tokenSocial;
    const email = socialData.email || getValues('email');
    try {
      const resp: ResponseTokenSocial = await sendSocialData(
        token,
        providerSocial,
        email
      );
      setValue('isSocial', true);
      setValue('provider', providerSocial);
      if (resp.email) setValue('email', resp.email);
      await handleSubmit(
        async (data: any) => await submitForm(data, nextStep, providerSocial)
      )();
    } catch (error: any) {
      console.log(error);
      showErrorMessageServer();
    }
  }
  async function skipStep() {
    try {
      setValue('cellPhoneNumber', '');
      if (notCreateAccount) {
        nextStep();
      } else {
        setLoading(true);
        if (socialWithoutEmail && !getValues('password')) {
          sendSocialToken();
        } else if (providerSocial) {
          setValue('isSocial', true);
          setValue('provider', providerSocial);
          if (socialData.email) setValue('email', socialData.email);

          handleSubmit(async (data: any) =>
            submitForm(data, nextStep, providerSocial)
          )();
        } else {
          handleSubmit((data: any) => submitForm(data, nextStep))();
        }
      }
    } catch (e) {
      setLoading(false);
      showErrorMessageServer();
      console.error(e);
    }
  }

  function nextStep() {
    if (nextPath) {
      handleChangeStep(nextPath);
    }
    setLoading(false);
  }

  return (
    <div className="flex flex-column">
      <LogoText />
      <h2 className="color-text-default">
        {isSignUp(originDomainForm)
          ? 'valide seu celular'
          : 'complete seus dados'}
      </h2>
      <h6 className="mti-8 mbi-8 color-text-default">
        Adicionar número de celular
      </h6>
      <span className="md mt-4">
        Para validar seu número de celular é necessário digitar um código de 6
        dígitos enviado por SMS
      </span>
      <span className="md mt-8">
        O seu número de celular será utilizado para recuperar sua conta caso
        você esqueça ou perca seu e-mail
      </span>

      <div className="flex flex-column mt-16">
        <FormField
          label="número de celular"
          prefix
          maxLength="9"
          labelPrefix="+55"
          iconPrefix="flagBrasil"
          placeholder="digite seu telefone celular"
          inputMode="tel"
          name="cellPhoneNumber"
          onKeyUp={mobilePhoneMask}
          register={register('cellPhoneNumber')}
          error={errors}
          touchedFields={touchedFields}
        />
        <Button
          className="mt-8 mbi-0"
          full
          loading={loading}
          onClick={changeToViewToken}
          disabled={
            !!errors.cellPhoneNumber ||
            (fieldValidate.length && fieldValidate.length < 13) ||
            !fieldValidate.length
          }
        >
          <ButtonLabel>avançar</ButtonLabel>
        </Button>

        {allowSkipStep ? (
          <Button
            className="mt-16"
            customColors="btn-white"
            full
            loading={loading}
            onClick={skipStep}
          >
            <ButtonLabel loading={loading}>pular</ButtonLabel>
          </Button>
        ) : null}
      </div>
    </div>
  );
}
