import './SignUp.scss';
import { useContext, useState } from 'react';
import LogoText from '../../components/LogoText/LogoText';
import FormField from '../../components/FormField/FormField';
import Button from '../../components/Button/Button';
import ButtonLabel from '../../components/Button/ButtonLabel';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import CheckForcePassword from '../../components/CheckForcePassword/CheckForcePassword';
import { checkEmailExist } from '../../services/queryController';
import SocialMedia from '../../components/SocialMedia/SocialMedia';
import { useControllerFlowStepsContext } from '../../hooks/ControllerFlowStepsContext';
import {
  checkSocialName,
  setOriginDomainCustom
} from '../../utils/GenericFunctions';
import SocialName from '../../components/SocialName/SocialName';
import Date from '../../components/Date/Date';
import Terms from '../Terms';
import { passwordMask } from '../../utils/Masks';
import { CODE_ACCOUNT_WITH_EMAIL_NOT_FOUND } from '../../data/Constants';
import { ResponseTokenSocial } from '../../model/Models';

export interface SignUpProps {
  allowSkipStep?: boolean;
  updateData?: boolean;
  previousPath?: number | null;
  nextPath?: number | null;
  handleChangeStep(e: number): void;
  context: any;
}

export default function SignUp({
  nextPath,
  previousPath,
  handleChangeStep,
  context
}: SignUpProps) {
  const { setSocialData, showErrorMessageServer } =
    useControllerFlowStepsContext();
  const {
    register,
    trigger,
    watch,
    errors,
    touchedFields,
    getValues,
    setError,
    setValue,
    originDomainForm
  } = useContext<any>(context);
  const navigate = useNavigate();
  const [fieldsValidate, setValueValidateFields] = useState({
    email: '',
    password: '',
    checkPassword: '',
    acceptTermsOfUse: false,
    hasSocialName: false,
    socialName: '',
    birthDate: ''
  });
  const [emailValidated, setEmailvalidated] = useState(false);

  useEffect(() => {
    setOriginDomainCustom();
  }, []);

  useEffect(() => {
    if (originDomainForm) {
      setValue('provider', '');
      setValue('socialId', '');
      const url = `/${originDomainForm}/criar-conta`;
      navigate(url, { replace: true });
    }
  }, [originDomainForm]);

  async function checkValidateFields() {
    return await trigger(['email', 'password', 'checkPassword']);
  }

  useEffect(() => {
    const subscription = watch((data: any) => {
      setValueValidateFields(data);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  function nextStep() {
    checkValidateFields().then((response) => {
      if (response && nextPath) {
        setSocialData({} as ResponseTokenSocial);
        handleChangeStep(nextPath);
      }
    });
  }

  async function emailExist(e: React.FormEvent<HTMLInputElement>) {
    setEmailvalidated(false);
    const email = getValues('email').trim();
    e.currentTarget.value = email;

    if (email && !errors.email) {
      try {
        const response: any = await checkEmailExist(email);
        if (response.exist) {
          setError('email', {
            message: (
              <span>
                endereço de e-mail já vinculado a uma conta.
                <span
                  onClick={redirectToLogin}
                  className="strong link-intern-text ml-3 text-purple"
                >
                  fazer login.
                </span>
              </span>
            )
          });
        } else if (response?.exist === false) setEmailvalidated(true);
      } catch (error: any) {
        if (error?.code === CODE_ACCOUNT_WITH_EMAIL_NOT_FOUND) {
          setEmailvalidated(true);
        } else {
          console.error(error);
          showErrorMessageServer();
        }
      }
    }
  }

  function redirectToLogin() {
    const url =
      originDomainForm === 'signup'
        ? '/signin'
        : originDomainForm === 'sbt/signup'
        ? '/sbt/signin'
        : '/videos/signin';
    navigate(url);
  }

  async function handleCheckPasswordIsEqual() {
    await trigger(['password', 'checkPassword']);
  }

  return (
    <div className="flex flex-column">
      <div className="signup-header-container">
        <LogoText />
        <span className="md color-text-default text-right">
          Já tem cadastro? <br />
          <a
            onClick={redirectToLogin}
            className="strong link-intern-text ml-3 text-purple"
            rel="noreferrer"
          >
            Fazer login!
          </a>
        </span>
      </div>

      <h2 className="color-text-default">criar conta</h2>
      <span className="md mt-4 color-text-default">
        Aproveite o melhor do SBT com uma única conta!
      </span>

      <SocialMedia context={context} label="ou preencha" />

      <div className="flex flex-column mt-14">
        <SocialName
          register={register}
          errors={errors}
          touchedFields={touchedFields}
          setValue={setValue}
        />
        <Date
          register={register}
          errors={errors}
          touchedFields={touchedFields}
        />

        <FormField
          name="email"
          label="e-mail"
          type="email"
          inputMode="email"
          placeholder="digite seu e-mail"
          onBlur={emailExist}
          register={register('email')}
          error={errors}
          touchedFields={touchedFields}
        />
        <CheckForcePassword
          value={fieldsValidate.password}
          errorField={!errors.password}
        >
          <FormField
            name="password"
            label="senha"
            type="password"
            placeholder="digite sua senha"
            register={register('password')}
            onInput={passwordMask}
            error={errors}
            touchedFields={touchedFields}
            sufix
            isPassword
            onBlur={handleCheckPasswordIsEqual}
          />
        </CheckForcePassword>
        <FormField
          name="checkPassword"
          label="confirme a senha"
          type="password"
          placeholder="repita sua senha"
          register={register('checkPassword')}
          onInput={passwordMask}
          error={errors}
          touchedFields={touchedFields}
          sufix
          isPassword
          onBlur={handleCheckPasswordIsEqual}
        />

        <Terms
          nextPath={nextPath}
          handleChangeStep={handleChangeStep}
          previousPath={previousPath}
          context={context}
        />

        <Button
          className="mt-8"
          full
          onClick={nextStep}
          disabled={
            fieldsValidate.password?.length < 6 ||
            !!errors.email ||
            !touchedFields.email ||
            !!errors.password ||
            !touchedFields.password ||
            !!errors.checkPassword ||
            !emailValidated ||
            fieldsValidate.checkPassword?.trim()?.length < 6 ||
            !fieldsValidate?.acceptTermsOfUse ||
            // Personal Data
            !!errors?.userName ||
            !getValues('userName') ||
            (fieldsValidate.hasSocialName &&
              checkSocialName(fieldsValidate.socialName)) ||
            !fieldsValidate?.birthDate ||
            !!errors?.birthDate ||
            !touchedFields?.birthDate
          }
        >
          <ButtonLabel>avançar</ButtonLabel>
        </Button>
      </div>
    </div>
  );
}
