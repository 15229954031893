import { createElement, useCallback, useMemo, useState } from 'react';
import Card from '../../../../components/Card/Card';
import Container from '../../../../components/Container/Container';
import Step from '../../../../components/Wizard/Step';
import Wizard from '../../../../components/Wizard/Wizard';
import RegisterConfirmPhone from './RegisterConfirmPhone';
import RegisterPhone from './RegisterPhone';
import RegisterPhoneCheckToken from './RegisterPhoneCheckToken';

export default function RegisterPhoneWizard() {
  const components: any = {
    PhoneNumber: {
      id: 'cadastrar/telefone',
      component: RegisterPhone
    },
    RegisterPhoneCheckToken: {
      id: 'cadastrar/token',
      component: RegisterPhoneCheckToken
    },
    RegisterConfirmPhone: {
      id: 'cadastrar/confirmar-telefone',
      component: RegisterConfirmPhone
    }
  };

  const [currentStep, setCurrentStep] = useState(0);
  const [defaultSteps] = useState([
    getComponent('PhoneNumber'),
    getComponent('RegisterPhoneCheckToken'),
    getComponent('RegisterConfirmPhone')
  ]);

  function getComponent(key: string) {
    const component = components[key];
    return component;
  }

  const handleChangeStep = useCallback((indexStep: number) => {
    setCurrentStep(indexStep);
  }, []);

  const steps = useMemo(() => defaultSteps, [defaultSteps]);

  const buildSteps = useCallback(() => {
    const lastIndex = steps.length - 1;
    return steps.map((step, index) => {
      const component = createElement(step.component, {
        previousPath: index > 0 ? index - 1 : null,
        nextPath: index < lastIndex ? index + 1 : null,
        handleChangeStep
      });
      return (
        <Step id={index} currentStep={currentStep} key={index}>
          {component}
        </Step>
      );
    });
  }, [currentStep, handleChangeStep, steps]);

  return (
    <Container>
      <Card width="400px" minHeight="550px" center>
        <form className="width-full" onSubmit={(e) => e.preventDefault()}>
          <Wizard>{buildSteps()}</Wizard>
        </form>
      </Card>
    </Container>
  );
}
