import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import ButtonLabel from '../../../components/Button/ButtonLabel';
import Icon from '../../../components/Icon/Icon';
import LogoText from '../../../components/LogoText/LogoText';
import { useControllerFlowStepsContext } from '../../../hooks/ControllerFlowStepsContext';

export default function Success() {
  const navigate = useNavigate();

  const { setControllerViews, getOrigin } = useControllerFlowStepsContext();

  const url = `${
    !getOrigin()
      ? '/recuperar-senha/sucesso'
      : '/' + getOrigin() + '/recuperar-senha/sucesso'
  }`;

  useEffect(() => {
    navigate(url);
  }, [navigate]);

  function redirect() {
    setControllerViews({});
    navigate(`/${getOrigin()}`);
  }
  return (
    <div className="flex flex-column">
      <LogoText />

      <div className="flex flex-column mt-16 success-card">
        <Icon name="checkedSuccessfully" />
        <h2 className="mti-18 mbi-16">tudo certo!</h2>

        <span className="md mb-24">
          Sua senha da Conta SBT foi alterada. <br />
          Agora é só aproveitar!
        </span>

        <Button className="mt-8" full onClick={redirect}>
          <ButtonLabel>fazer login</ButtonLabel>
        </Button>
      </div>
    </div>
  );
}
