import Button from '../../components/Button/Button';
import ButtonLabel from '../../components/Button/ButtonLabel';
import Card from '../../components/Card/Card';
import Container from '../../components/Container/Container';
import Icon from '../../components/Icon/Icon';
import LogoText from '../../components/LogoText/LogoText';
import './ValidateEmail.scss';

export default function ValidateEmailLink() {
  const error = false;
  return (
    <Container>
      <Card width="400px" minHeight="550px" center>
        <div className="flex flex-column" style={{ width: '100%' }}>
          <LogoText />

          <div
            className="flex flex-column mt-16 success-card"
            style={{ width: '100%' }}
          >
            <Icon name={error ? 'emailError' : 'emailApprove'} />
            <h2 className="mbi-16">{error ? 'Ops!' : 'tudo certo!'}</h2>
            {error ? (
              <span className="md mb-24">
                Seu link de validação expirou. <br />
                Faça login para acessar sua Conta SBT.
              </span>
            ) : (
              <span className="md mb-24">
                o e-mail da sua Conta SBT foi validado.
                <br /> agora é só alegria!
              </span>
            )}

            <Button className="mt-8" full>
              <ButtonLabel>fazer login</ButtonLabel>
            </Button>
          </div>
        </div>
      </Card>
    </Container>
  );
}
