import { useState } from 'react';
import FormField from './FormField';
import './FormField.scss';

interface FormFieldSelectProps {
  items?: any;
  name?: string;
  label?: string;
  placeholder?: string;
  touchedFields?: any;
  error?: any;
  register?: any;
  disabled?: boolean;
  selectValue: (value: any) => any;
}

export default function FormFieldSelect({
  items = [],
  name,
  label,
  register,
  placeholder,
  touchedFields,
  error,
  disabled,
  selectValue
}: FormFieldSelectProps) {
  const [showOptions, setShowOptions] = useState(false);

  function onChange(item: any) {
    selectValue(item);
    setShowOptions(false);
  }
  function mountValues() {
    return items.map((item: any, index: number) => {
      return (
        <span onClick={() => onChange(item)} key={index}>
          {item.value}
        </span>
      );
    });
  }

  return (
    <div>
      <div
        className="select-container form-select-input"
        onClick={() => setShowOptions((show) => !show)}
      >
        <FormField
          customClass="mbi-0"
          label={label}
          disabled={disabled}
          placeholder={placeholder}
          register={register}
          touchedFields={touchedFields}
          name={name}
          error={error}
          onInput={onChange}
          sufix
          iconSufix={!showOptions ? 'arrowDown' : 'arrowUp'}
        />
      </div>
      {items.length && showOptions ? (
        <div className={'form-select'}>{mountValues()}</div>
      ) : null}
    </div>
  );
}
