import Success from '../../components/Success/Success';
import './Success.scss';

interface SuccessMergeProps {
  context: any;
  allowSkipStep?: boolean;
  updateData?: boolean;
  previousPath?: number | null;
  nextPath?: number | null;
  handleChangeStep?(e: number): void;
  viewSuccess?: any;
}

export default function SuccessMerge({ context }: SuccessMergeProps) {
  return (
    <Success context={context}>
      <span className="md mb-24 color-text-default">
        O e-mail da sua Conta SBT foi validado.
        <br /> agora é só alegria!
      </span>
    </Success>
  );
}
