import './FormField.scss';
import Icon from '../Icon/Icon';
import { useState } from 'react';
import React from 'react';
interface FormFieldProps {
  name?: string;
  label?: string;
  inputMode?: string;
  type?: string;
  placeholder?: string;
  register?: any;
  touchedFields?: any;
  disabled?: boolean;
  error?: any;
  prefix?: boolean;
  sufix?: boolean;
  labelSufix?: string;
  maxLength?: string;
  autofocus?: boolean;
  labelPrefix?: string;
  iconSufix?: string;
  iconPrefix?: string;
  customClass?: string;
  isPassword?: boolean;
  onKeyUp?: (e: React.FormEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  onInput?: (e: React.FormEvent<HTMLInputElement>) => void;
}

export default function FormField({
  name = '',
  prefix,
  iconPrefix,
  labelPrefix,
  sufix,
  labelSufix,
  iconSufix,
  autofocus,
  maxLength = '200',
  label = '',
  register,
  touchedFields,
  placeholder = '',
  type = 'text',
  inputMode = 'text',
  error = {},
  customClass = '',
  disabled,
  isPassword,
  onKeyUp,
  onKeyDown,
  onBlur,
  onInput
}: FormFieldProps) {
  const [visibleIconPassword, setVisibleIconPassword] = useState(false);

  function mountPrefix() {
    if (prefix) {
      return (
        <div className="form-field-prefix">
          {iconPrefix ? <Icon name={iconPrefix} /> : false}
          {labelPrefix}
        </div>
      );
    }

    return false;
  }

  function mountSufix() {
    if (sufix) {
      return (
        <div
          className="form-field-sufix"
          onClick={() =>
            isPassword ? setVisibleIconPassword((state) => !state) : false
          }
        >
          {iconSufix && !isPassword ? <Icon name={iconSufix} /> : false}
          {isPassword ? (
            <Icon name={visibleIconPassword ? 'eyeClose' : 'eye'} />
          ) : (
            false
          )}
          {labelSufix}
        </div>
      );
    }

    return false;
  }

  return (
    <>
      <div
        className={
          `form-field ${customClass} ` +
          (disabled ? ' field-disabled' : '') +
          (error[name] && !disabled && !!touchedFields[name]
            ? ' input-error'
            : '')
        }
      >
        {mountPrefix()}

        <label htmlFor={name}>{label}</label>
        <input
          id={name}
          autoFocus={autofocus}
          {...register}
          disabled={disabled}
          type={isPassword && visibleIconPassword ? 'text' : type}
          placeholder={placeholder}
          inputMode={inputMode}
          onBlurCapture={onBlur}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onInput={onInput}
          maxLength={maxLength}
        />
        {mountSufix()}
        {error[name] && !disabled && !!touchedFields[name] ? (
          <span className="input-error-label">{error[name].message}</span>
        ) : (
          false
        )}
      </div>
    </>
  );
}

// ex: Implementation
{
  /* <FormField label="email" placeHolder="Preencha seu email" type="email" />
      or
     <FormField
        prefix
        labelPrefix="+ 55"
        iconPrefix="flagBrasil"
        label="phone"
        placeHolder="Preencha seu telefone"
        type="text"/>
  */
}
