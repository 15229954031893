import { ReactNode } from 'react';

interface WizardProps {
  className?: string;
  children: ReactNode;
}
const Wizard = (props: WizardProps) => {
  return <div className={props.className}>{props.children}</div>;
};

export default Wizard;
