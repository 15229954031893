import { createContext, useContext } from 'react';
import {
  FieldErrorsImpl,
  useForm,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormTrigger,
  UseFormUnregister,
  UseFormResetField,
  UseFormWatch,
  UseFormGetFieldState
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createForm, FormAccountRecoveryValues } from './FormAccountRecovery';

export interface AccountRecoveryContextData {
  register: UseFormRegister<FormAccountRecoveryValues>;
  handleSubmit: UseFormHandleSubmit<FormAccountRecoveryValues>;
  errors: Partial<FieldErrorsImpl<FormAccountRecoveryValues>>;
  touchedFields: Partial<Readonly<any>>;
  trigger: UseFormTrigger<FormAccountRecoveryValues>;
  origin: string | null;
  submitForm(payload: any, callBack: any): void;
  watch: UseFormWatch<FormAccountRecoveryValues>;
  getValues: UseFormGetValues<FormAccountRecoveryValues>;
  setValue: UseFormSetValue<FormAccountRecoveryValues>;
  setFocus: UseFormSetFocus<FormAccountRecoveryValues>;
  setError: UseFormSetError<FormAccountRecoveryValues> | any;
  isValid: boolean;
  unregister: UseFormUnregister<FormAccountRecoveryValues>;
  resetField: UseFormResetField<FormAccountRecoveryValues>;
  getFieldState: UseFormGetFieldState<FormAccountRecoveryValues>;
}

export const AccountRecoveryContext = createContext(
  {} as AccountRecoveryContextData
);

const AccountRecoveryProvider = ({ children }: any) => {
  const formFields = createForm();
  const {
    register,
    unregister,
    trigger,
    watch,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    setError,
    resetField,
    getFieldState,
    formState: { errors, touchedFields, isValid }
  } = useForm<FormAccountRecoveryValues>({
    mode: 'onChange',
    defaultValues: {
      isSocial: false
    },
    resolver: yupResolver(formFields, { abortEarly: false })
  });

  async function submitForm(formValues: any, callBack: any) {
    try {
      callBack();
    } catch (error) {
      console.log('submitform error', error);
    }
  }

  return (
    <AccountRecoveryContext.Provider
      value={{
        origin,
        register,
        unregister,
        handleSubmit,
        errors,
        submitForm,
        touchedFields,
        trigger,
        getValues,
        setValue,
        setFocus,
        watch,
        setError,
        isValid,
        resetField,
        getFieldState
      }}
    >
      {children}
    </AccountRecoveryContext.Provider>
  );
};
export const useAccountRecoveryContext = () =>
  useContext<AccountRecoveryContextData>(AccountRecoveryContext);
export default AccountRecoveryProvider;
