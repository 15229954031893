import { ReactNode } from 'react';
import './ToolTip.scss';

interface ToolTipProps {
  text?: string;
  element?: any;
  children: ReactNode;
}

export default function ToolTip({ text, children, element }: ToolTipProps) {
  return (
    <div className="tooltip">
      {children}
      {element ? element : <span className="tooltiptext">{text}</span>}
    </div>
  );
}
