import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import ButtonLabel from '../../../components/Button/ButtonLabel';
import FormFieldTokenNumber from '../../../components/FormField/FormFieldTokenNumber';
import LogoText from '../../../components/LogoText/LogoText';
import { useControllerFlowStepsContext } from '../../../hooks/ControllerFlowStepsContext';
import {
  createTokenRecoveryPassword,
  validateToken
} from '../../../services/tokenValidation';
import AlertBox, {
  AlertBoxHandles
} from '../../../components/AlertBox/AlertBox';
import { useAccountRecoveryContext } from '../../../data/AccountRecovery/AccountRecoveryContext';
import { getSocialsLoginByEmail } from '../../../services/queryController';
import { checkUserHasPassword } from '../../../services/user';
import { ErrorMessages } from '../../../data/ErrorMessages';
import { DEFAULT_API_COOLDOWNS } from '../../../data/Constants';
import { AttemptsTypeEnum } from '../../../enums/AttemptsTypeEnum';
import { convertSecondsToMMSS } from '../../../utils/GenericFunctions';

export interface CheckTokenProps {
  goBackView?: React.Dispatch<React.SetStateAction<boolean>>;
  callCreateToken(e: any): any;
  previousPath?: number;
  nextPath?: number | null;
  handleChangeStep(e: number): void;
  context: any;
}

export default function CheckToken({
  nextPath,
  handleChangeStep,
  previousPath
}: CheckTokenProps) {
  const {
    getOrigin,
    setControllerViews,
    showCooldownErrorMessage,
    showErrorMessageServer,
    controllerViews,
    attempts,
    increaseAttemptByType
  } = useControllerFlowStepsContext();
  const { getValues, setValue } = useAccountRecoveryContext();
  const [fieldValidate, setValueFieldValidate] = useState({
    value: '',
    isValid: false
  });
  const [errorToken, setErrorToken] = useState(false);
  const [resendingCode, setResendingCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [runTime, setRunTime] = useState(false);

  useEffect(() => {
    timeOutResend(attempts?.VALIDATE_EMAIL_RECOVERY_PASSWORD);
  }, []);

  function timeOutResend(intervalPos: number) {
    setRunTime(true);
    let initSeconds = DEFAULT_API_COOLDOWNS[intervalPos] || 60;
    setSeconds(initSeconds);
    const interval = setInterval(() => {
      if (initSeconds == 0) {
        clearInterval(interval);
        setSeconds(0);
        setRunTime(false);
      } else {
        setSeconds(--initSeconds);
      }
    }, 1000);
  }

  const url = `${
    !getOrigin()
      ? '/recuperar-senha/token'
      : '/' + getOrigin() + '/recuperar-senha/token'
  }`;
  const navigate = useNavigate();

  useEffect(() => {
    navigate(url);
  }, [navigate]);

  function backPage() {
    if (previousPath == 0 || previousPath) {
      handleChangeStep(previousPath);
    }
  }

  window.onpopstate = () => {
    backPage();
  };

  async function checkToken() {
    const valueKey = getValues('email');

    if (valueKey && fieldValidate.value) {
      setLoading(true);
      try {
        await validateToken(
          valueKey,
          fieldValidate.value,
          ErrorMessages.CODE_EMAIL_FORGOT_PASSWORD
        );

        setValue('code', fieldValidate.value);

        const responsePendingData = await getSocialsLoginByEmail(valueKey);

        const responseHasPassword = await checkUserHasPassword(valueKey);

        if (responseHasPassword.hasPassword)
          setControllerViews({ ...controllerViews, hasPassword: true });
        else
          setControllerViews({
            ...controllerViews,
            socialData: responsePendingData,
            hasPassword: false
          });

        nextStep();
      } catch (error) {
        setResendingCode(false);
        setErrorToken(true);
        setLoading(false);
      }
    }
  }

  async function resendCode() {
    setErrorToken(false);
    setResendingCode(true);
    await postCreateToken();
    increaseAttemptByType(AttemptsTypeEnum.VALIDATE_EMAIL_RECOVERY_PASSWORD);
    timeOutResend(attempts.VALIDATE_EMAIL_RECOVERY_PASSWORD + 1);
  }

  async function postCreateToken() {
    const formatValue = getValues('email');
    setLoading(true);
    try {
      await createTokenRecoveryPassword(formatValue);
      openAlert();
      setLoading(false);
    } catch (error: any) {
      if (error?.statusCode === 429) showCooldownErrorMessage();
      else showErrorMessageServer();
    }
  }

  const alertBoxRef = useRef<AlertBoxHandles>(null);
  function openAlert() {
    alertBoxRef.current?.openAlert();
  }

  // function getPhoneNumber() {
  //   const phone = getValues('cellPhoneNumber');
  //   if (!phone) return '';
  //   try {
  //     const [areaCode, number] = phone.split(' ');
  //     const splitNumber = number.split('-');
  //     return `${areaCode} XXXXX-${splitNumber[1]}`;
  //   } catch (error) {
  //     return '';
  //   }
  // }

  function getEmail() {
    const email = getValues('email');
    if (!email) return '';
    try {
      return email;
    } catch (error) {
      return '';
    }
  }

  function nextStep() {
    if (nextPath) handleChangeStep(nextPath);
  }

  return (
    <>
      <div className="flex flex-column">
        <LogoText />
        <h2 className="color-text-default">esqueceu a senha?</h2>
        <h6 className="md mti-8 mbi-8 color-text-default">
          Informe o código de validação
        </h6>
        <span className="md mt-4 mb-24 color-text-default">
          <span>Digite o código enviado para o e-mail</span>
          <br />
          <span className="md-bold-700">{getEmail()}</span>
          <span className="link ml-6" onClick={backPage}>
            alterar e-mail
          </span>
        </span>

        <FormFieldTokenNumber
          fieldValidate={fieldValidate}
          errorToken={errorToken}
          setState={setValueFieldValidate}
          resendingCode={resendingCode}
        />

        <div className="flex flex-column mt-16">
          <Button
            className="mt-8 mbi-0"
            full
            onClick={checkToken}
            loading={loading}
            disabled={!fieldValidate.isValid}
          >
            <ButtonLabel loading={loading}>confirmar código</ButtonLabel>
          </Button>
          <Button
            className="mt-16 mbi-0"
            customColors="btn-outline"
            full
            onClick={resendCode}
            loading={loading}
            disabled={runTime}
          >
            <ButtonLabel>
              reenviar código {convertSecondsToMMSS(seconds)}
            </ButtonLabel>
          </Button>
          <Button
            className="mt-16"
            customColors="btn-white"
            full
            onClick={backPage}
          >
            <ButtonLabel>voltar</ButtonLabel>
          </Button>
        </div>
      </div>
      <AlertBox ref={alertBoxRef} text="código enviado com sucesso" />
    </>
  );
}
