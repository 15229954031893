import { ReactNode, useEffect, useState } from 'react';
import './CheckForcePassword.scss';
import Icon from '../Icon/Icon';
import ToolTip from '../ToolTip/ToolTip';

interface CheckForcePassword {
  value: string;
  errorField: boolean;
  children: ReactNode;
}

export default function CheckForcePassword({
  value,
  errorField,
  children
}: CheckForcePassword) {
  const [statusBar, setStatusBar] = useState(0);
  const [statusBarLabel, setStatusBarLabel] = useState({
    label: '',
    color: ''
  });

  const toolTip = (
    <span className="sm tooltiptext">
      <strong>dicas de senha forte:</strong>
      <br />
      Ter ao menos uma letra minúscula
      <br />
      Ter ao menos uma letra maiúscula
      <br />
      Ter ao menos um número
      <br />
      Ter um caractere especial (ex: !, #, @, $)
    </span>
  );

  const regexNumber = /\d/g;
  const regexLowerCase = /[a-z]/g;
  const regexUpperCase = /[A-Z]/g;

  function testLetterUpperCase(statusBarInc: number) {
    return regexUpperCase.test(value) ? statusBarInc + 25 : statusBarInc;
  }

  function testLetterLowerCase(statusBarInc: number) {
    return regexLowerCase.test(value) ? statusBarInc + 25 : statusBarInc;
  }

  function testValueHaveNumber(statusBarInc: number) {
    const regexNumber = /\d/g;
    return regexNumber.test(value) ? statusBarInc + 25 : statusBarInc;
  }

  function testValueHaveSpecialCharactere() {
    const regexNumber = /!|#|@|\$/g;
    return regexNumber.test(value);
  }

  function testValueGreaterFive(statusBarInc: number) {
    return value?.length > 6 &&
      regexNumber.test(value) &&
      testValueHaveSpecialCharactere()
      ? statusBarInc + 25
      : statusBarInc;
  }

  function checkMin6Letters() {
    return value?.length < 6;
  }

  function setLabelColorStatus() {
    const statusBarLabelValue: any = {
      [25]: {
        label: 'fraca',
        color: '#A32929'
      },
      [50]: {
        label: 'moderada',
        color: '#7D7300'
      },
      [75]: {
        label: 'moderada',
        color: '#7D7300'
      },
      [100]: {
        label: 'forte',
        color: '#00371E'
      }
    };

    if (statusBarLabelValue[statusBar]) {
      setStatusBarLabel(statusBarLabelValue[statusBar]);
    }
  }

  useEffect(() => {
    let statusBarInc = 0;
    if (value?.length >= 6) {
      statusBarInc = testValueGreaterFive(statusBarInc);
      statusBarInc = testLetterUpperCase(statusBarInc);
      statusBarInc = testLetterLowerCase(statusBarInc);
      statusBarInc = testValueHaveNumber(statusBarInc);
    }
    setStatusBar(statusBarInc);
    setLabelColorStatus();
  }, [statusBar, value]);

  function renderComponent() {
    return (
      <>
        <div className="check-force__bar">
          <span
            style={{
              width: `${statusBar}%`,
              backgroundColor: `${statusBarLabel.color}`
            }}
          ></span>
        </div>
        <div className="check-force__tip">
          <div style={{ transform: 'translateY(-5px)' }}>
            <span className="sm-bold">crie uma senha com:</span>
            <div className="check-force__requires">
              <span
                className={
                  'sm ' + (checkMin6Letters() ? 'alert-text' : 'success-text')
                }
              >
                No mínimo 6 caracteres
              </span>
            </div>
          </div>

          <span
            className="sm-bold flex"
            style={{
              color: `${statusBarLabel.color}`
            }}
          >
            {statusBarLabel.label}
            <ToolTip element={toolTip}>
              <Icon name="info" />
            </ToolTip>
          </span>
        </div>
      </>
    );
  }

  return (
    <div className="check-force">
      {children}
      {value?.length && errorField ? renderComponent() : null}
    </div>
  );
}
