import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import ButtonLabel from '../../../../components/Button/ButtonLabel';
import Card from '../../../../components/Card/Card';
import CheckForcePassword from '../../../../components/CheckForcePassword/CheckForcePassword';
import Container from '../../../../components/Container/Container';
import FormField from '../../../../components/FormField/FormField';
import LogoText from '../../../../components/LogoText/LogoText';
import { PASS_MAX_LENGTH } from '../../../../data/Constants';
import { useUpdateUserDataContext } from '../../../../data/UpdateUserData/UpdateUserDataContext';
import { useAuthContext } from '../../../../hooks/AuthContext';
import { useControllerFlowStepsContext } from '../../../../hooks/ControllerFlowStepsContext';
import { useControlNavBarContext } from '../../../../hooks/ControlNavBar';
import { updatePassword } from '../../../../services/user';
import { passwordMask } from '../../../../utils/Masks';

export default function UserCreatePassword() {
  const { checkAuth } = useAuthContext();
  const {
    register,
    watch,
    errors,
    trigger,
    touchedFields,
    getValues,
    setValue,
    setUpdatedData,
    sizeView
  } = useUpdateUserDataContext();
  const { setAlertBox } = useControllerFlowStepsContext();
  const [fieldsValidate, setValueValidateFields] = useState({
    password: '',
    checkPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const { setShowButton, setGoBackNavigate } = useControlNavBarContext();
  const navigate = useNavigate();

  useEffect(() => {
    setShowButton(true);
  }, []);

  useEffect(() => {
    setGoBackNavigate({
      path: `/menu-de-conta/atualizar-dados${
        sizeView[0] < 576 ? '/conta' : ''
      }`,
      param: {}
    });
  }, [sizeView]);

  window.onpopstate = () => {
    redirect();
  };

  function redirect() {
    navigate(
      `/menu-de-conta/atualizar-dados${sizeView[0] < 576 ? '/conta' : ''}`,
      { replace: true }
    );
  }

  useEffect(() => {
    const subscription = watch((data: any) => {
      setValueValidateFields(data);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  async function checkValidateFields() {
    return await trigger(['password', 'checkPassword']);
  }

  async function updatePasswordData() {
    const isValidFields = await checkValidateFields();
    if (isValidFields && checkAuth().user.email) {
      setLoading(true);
      const password = getValues('password');
      try {
        await updatePassword(checkAuth().user.email, password);
        setUpdatedData(true);
        setValue('password', '');
        setValue('checkPassword', '');
        setAlertBox({
          message: 'sua senha foi criada',
          error: false
        });
      } catch (error) {
        console.error(error);
        setAlertBox({
          message: 'sua senha não foi criada',
          error: true
        });
        setLoading(false);
      } finally {
        redirect();
      }
    }
  }

  return (
    <Container>
      <Card width="400px" minHeight="550px" center>
        <div className="flex flex-column width-full">
          <LogoText />
          <h2 className="color-text-default">criar senha</h2>
          <span className="md mt-4 color-text-default">
            Crie uma senha para acessar sua conta
          </span>

          <div className="flex flex-column mt-16">
            <div className="form-container" style={{ zIndex: 1 }}>
              <CheckForcePassword
                value={fieldsValidate.password}
                errorField={!errors.password}
              >
                <FormField
                  name="password"
                  label="nova senha"
                  type="password"
                  maxLength={PASS_MAX_LENGTH}
                  placeholder="digite sua senha"
                  register={register('password')}
                  onInput={passwordMask}
                  error={errors}
                  touchedFields={touchedFields}
                  sufix
                  isPassword
                />
              </CheckForcePassword>
            </div>
            <div className="form-container">
              <FormField
                name="checkPassword"
                label="confirme a nova senha"
                type="password"
                maxLength={PASS_MAX_LENGTH}
                placeholder="repita sua senha"
                register={register('checkPassword')}
                error={errors}
                touchedFields={touchedFields}
                sufix
                isPassword
              />
            </div>

            <Button
              className="mt-24 mbi-0"
              full
              disabled={
                loading ||
                fieldsValidate.password?.length < 6 ||
                !!errors.password ||
                !touchedFields.password ||
                !!errors.checkPassword ||
                fieldsValidate.checkPassword?.length < 6
              }
              onClick={updatePasswordData}
            >
              <ButtonLabel loading={loading}>avançar</ButtonLabel>
            </Button>
            <Button
              className="mt-16 mbi-0"
              full
              customColors="btn-white"
              disabled={loading}
              onClick={redirect}
            >
              <ButtonLabel>voltar</ButtonLabel>
            </Button>
          </div>
        </div>
      </Card>
    </Container>
  );
}
