import React, { useCallback, useImperativeHandle, useState } from 'react';
import Loading from '../Loading/Loading';
import './LoadingFullScreen.scss';

export interface LoadingFullScreenHandles {
  openLoadingFullScreen: () => void;
  closeLoadingFullScreen: () => void;
}

const LoadingFullScreen: React.ForwardRefRenderFunction<
  LoadingFullScreenHandles
> = (_, ref) => {
  const [open, setOpen] = useState(false);

  const openLoadingFullScreen = useCallback(() => {
    setOpen(true);
  }, []);

  useImperativeHandle(ref, () => {
    return {
      openLoadingFullScreen,
      closeLoadingFullScreen
    };
  });

  function closeLoadingFullScreen() {
    setOpen(false);
  }

  return (
    <>
      {open ? (
        <div className="loading-full-screen">
          <Loading />
        </div>
      ) : null}
    </>
  );
};

export default React.forwardRef(LoadingFullScreen);
