import * as yup from 'yup';
import { schemaStringRequired } from '../SchemaFieldsRequired';

export interface FormDisableAccountValues {
  password: string;
}

export function createForm() {
  const objectFiels = {
    password: schemaStringRequired
  };

  return yup.object(objectFiels);
}
