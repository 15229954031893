import Icon from '../Icon/Icon';
import './SocialMedia.scss';

import { useControllerSocialMediaContext } from '../../hooks/ControllerSocialMedia';
import { setCookie } from '../../services/cookieController';
import { useAuthContext } from '../../hooks/AuthContext';
import GoogleLogin from 'react-google-login';
import {
  handleOAuthRedirectURI,
  isEdgeOnIos,
  shouldOpenOAuthWithRedirect
} from '../../utils/GenericFunctions';

const REACT_APP_APPID_GOOGLE = process.env.REACT_APP_APPID_GOOGLE || '';
const REACT_APP_SCOPE_GOOGLE_PROFILE =
  process.env.REACT_APP_SCOPE_GOOGLE_PROFILE;
const REACT_APP_SCOPE_GOOGLE_EMAIL = process.env.REACT_APP_SCOPE_GOOGLE_EMAIL;

export default function ButtonGoogle() {
  const { googleHandleCredentialResponse } = useControllerSocialMediaContext();
  const { redirectURL } = useAuthContext();

  return (
    <GoogleLogin
      clientId={REACT_APP_APPID_GOOGLE}
      scope={[
        REACT_APP_SCOPE_GOOGLE_EMAIL,
        REACT_APP_SCOPE_GOOGLE_PROFILE
      ].join(' ')}
      render={(renderProps: any) => (
        <div
          data-testid="btn-google"
          className={`social-medias__google ${
            renderProps.disabled ? 'disabled' : ''
          }`}
          onClick={() => {
            if (renderProps.disabled) return;

            if (redirectURL) setCookie('redirect', redirectURL);
            renderProps.onClick();
          }}
        >
          <Icon name="logoGoogle" />
        </div>
      )}
      buttonText="Login"
      onSuccess={(responseGoogle) =>
        googleHandleCredentialResponse(responseGoogle)
      }
      onFailure={(responseGoogle) => console.log(responseGoogle)}
      cookiePolicy={'single_host_origin'}
      uxMode={
        shouldOpenOAuthWithRedirect(redirectURL) || isEdgeOnIos()
          ? 'redirect'
          : 'popup'
      }
      redirectUri={
        shouldOpenOAuthWithRedirect(redirectURL) || isEdgeOnIos()
          ? handleOAuthRedirectURI('google')
          : undefined
      }
    />
  );
}
