import React, { useCallback, useImperativeHandle, useState } from 'react';
import './BackToAppFullScreen.scss';

import Button from '../Button/Button';
import ButtonLabel from '../Button/ButtonLabel';
import { getCookie } from '../../services/cookieController';
import { KEY_REFRESH_SESSION, KEY_SESSION } from '../../hooks/AuthContext';
import LogoText from '../LogoText/LogoText';
import Icon from '../Icon/Icon';
import Card from '../Card/Card';
import { getOriginDomain } from '../../utils/GenericFunctions';
import { ORIGIN_DOMAIN_SBT_GAMES } from '../../data/Constants';

export interface BackToAppFullScreenHandles {
  openBackToAppFullScreen: () => void;
  closeBackToAppFullScreen: () => void;
}

const BackToAppFullScreen: React.ForwardRefRenderFunction<
  BackToAppFullScreenHandles
> = (_, ref) => {
  const [open, setOpen] = useState(false);

  const openBackToAppFullScreen = useCallback(() => {
    setOpen(true);
  }, []);

  useImperativeHandle(ref, () => {
    return {
      openBackToAppFullScreen,
      closeBackToAppFullScreen
    };
  });

  function closeBackToAppFullScreen() {
    setOpen(false);
  }

  function tryOpenApp() {
    const token = getCookie(KEY_SESSION);
    const refreshToken = getCookie(KEY_REFRESH_SESSION) || '';

    if (getOriginDomain() === ORIGIN_DOMAIN_SBT_GAMES) {
      const appRedirect = getCookie('app_redirect');
      window.open(
        `${appRedirect}?token=${token}&refresh_token=${refreshToken}`,
        '_self'
      );
    } else {
      window.open(
        `intent://sbtnews?token=${token}&refresh_token=${refreshToken}#Intent;scheme=sbtnews;action=android.intent.action.VIEW;end`,
        '_self'
      );
    }
  }

  return (
    <>
      {open ? (
        <div className="back-to-app-full-screen">
          <Card width="400px" minHeight="550px" center>
            <div className="flex flex-column" style={{ width: '100%' }}>
              <LogoText />

              <div className="flex flex-column success-card">
                <Icon name="success" />
                <h2 className="mbi-16">tudo certo!</h2>

                <span className="md mb-24 color-text-default">
                  Sua Conta SBT já está pronta.
                  <br /> Agora é só aproveitar!
                </span>

                <Button className="mbi-0" full onClick={tryOpenApp}>
                  <ButtonLabel>voltar para o App</ButtonLabel>
                </Button>
              </div>
            </div>
          </Card>
        </div>
      ) : null}
    </>
  );
};

export default React.forwardRef(BackToAppFullScreen);
