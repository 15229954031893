import { useState } from 'react';
import LogoText from '../../../components/LogoText/LogoText';
import FormField from '../../../components/FormField/FormField';
import Button from '../../../components/Button/Button';
import ButtonLabel from '../../../components/Button/ButtonLabel';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import CheckForcePassword from '../../../components/CheckForcePassword/CheckForcePassword';
import { resetPassword } from '../../../services/user';
import { useAccountRecoveryContext } from '../../../data/AccountRecovery/AccountRecoveryContext';
import { useControllerFlowStepsContext } from '../../../hooks/ControllerFlowStepsContext';
import { PASS_MAX_LENGTH } from '../../../data/Constants';
import { passwordMask } from '../../../utils/Masks';

export interface PasswordRecoverySetPasswordProps {
  previousPath?: number | null;
  nextPath?: number | null;
  handleChangeStep(e: number): void;
  context: any;
}

export default function PasswordRecoverySetPassword({
  nextPath,
  handleChangeStep,
  previousPath
}: PasswordRecoverySetPasswordProps) {
  const { register, watch, errors, touchedFields, getValues } =
    useAccountRecoveryContext();
  const [loading, setLoading] = useState(false);
  const { showErrorMessageServer } = useControllerFlowStepsContext();
  const [, setAdvancePath] = useState(false);
  const [fieldsValidate, setValueValidateFields] = useState({
    password: '',
    checkPassword: ''
  });

  const { getOrigin } = useControllerFlowStepsContext();

  const url = `${
    !getOrigin()
      ? '/recuperar-senha/senha'
      : '/' + getOrigin() + '/recuperar-senha/senha'
  }`;
  const navigate = useNavigate();

  useEffect(() => {
    navigate(url);
  }, [navigate]);

  useEffect(() => {
    const subscription = watch((data: any) => {
      setValueValidateFields(data);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  function nextStep() {
    if (nextPath) {
      handleChangeStep(nextPath);
    }
  }

  async function updatePasswordData() {
    setLoading(true);
    const password = getValues('password');
    const email = getValues('email');
    try {
      const response = { email };
      await resetPassword(response.email, password, getValues('code') || '');
      setAdvancePath(true);
      nextStep();
    } catch (_) {
      showErrorMessageServer();
      setAdvancePath(false);
      setLoading(false);
    }
  }

  function backPage() {
    if (previousPath == 0 || previousPath) {
      handleChangeStep(previousPath - 1);
    }
  }

  window.onpopstate = () => {
    backPage();
  };

  return (
    <div className="flex flex-column">
      <LogoText />
      <h2>esqueceu sua senha?</h2>
      <span className="md mt-4">Crie uma senha para acessar sua conta</span>

      <div className="flex flex-column mt-16">
        <CheckForcePassword
          value={fieldsValidate.password}
          errorField={!errors.password}
        >
          <FormField
            name="password"
            label="senha"
            type="password"
            maxLength={PASS_MAX_LENGTH}
            placeholder="digite sua senha"
            register={register('password')}
            onInput={passwordMask}
            error={errors}
            touchedFields={touchedFields}
            sufix
            isPassword
          />
        </CheckForcePassword>
        <FormField
          name="checkPassword"
          label="confirme a senha"
          type="password"
          maxLength={PASS_MAX_LENGTH}
          placeholder="repita sua senha"
          register={register('checkPassword')}
          error={errors}
          touchedFields={touchedFields}
          sufix
          isPassword
        />

        <Button
          className="mt-8 mbi-0"
          full
          onClick={updatePasswordData}
          disabled={
            loading ||
            fieldsValidate.password?.length < 6 ||
            !!errors.password ||
            !touchedFields.password ||
            !!errors.checkPassword ||
            fieldsValidate.checkPassword?.length < 6
          }
        >
          <ButtonLabel loading={loading}>avançar</ButtonLabel>
        </Button>
        <Button
          className="mt-16"
          customColors="btn-white"
          full
          onClick={backPage}
        >
          <ButtonLabel>voltar</ButtonLabel>
        </Button>
      </div>
    </div>
  );
}
