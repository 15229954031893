import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../../components/Button/Button';
import ButtonLabel from '../../components/Button/ButtonLabel';
import Card from '../../components/Card/Card';
import Container from '../../components/Container/Container';
import LogoText from '../../components/LogoText/LogoText';
import { useAuthContext } from '../../hooks/AuthContext';
import { useControllerFlowStepsContext } from '../../hooks/ControllerFlowStepsContext';
import { reactivateAccount } from '../../services/accountController';
import {
  accDate,
  convertToDDMMYY,
  shouldOpenMenuAccount
} from '../../utils/GenericFunctions';
import { CTV_SUCCESS_PAGE_PATH } from '../../data/Constants';

export default function ReactiveAccount() {
  const { redirectToSite, redirectURL } = useAuthContext();
  const {
    statusAccountUser,
    setLoadingFullScreen,
    getOrigin,
    ctvCode,
    showErrorMessageServer
  } = useControllerFlowStepsContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const [inactiveDate, setInactiveDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    checkData();
  }, []);

  async function checkData() {
    setLoadingFullScreen(true);
    const email = search.get('email');
    if (email) {
      try {
        if (statusAccountUser.accountInactiveSince) {
          setInactiveDate(
            convertToDDMMYY(statusAccountUser.accountInactiveSince)
          );
          const calcDate = accDate(
            statusAccountUser.accountInactiveSince,
            30,
            'days'
          );
          setEndDate(calcDate.format('LL'));
        } else {
          const url = `/${getOrigin()}`;
          navigate(url);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingFullScreen(false);
      }
    }
  }

  async function sendReactivateAccount() {
    const email = search.get('email');
    if (
      email &&
      statusAccountUser.accountInactiveSince &&
      !statusAccountUser.accountActive
    ) {
      setLoading(true);
      try {
        await reactivateAccount(email);
        redirect();
      } catch (error) {
        setLoading(false);
        showErrorMessageServer();
        console.log('error', error);
      }
    }
  }

  function cancel() {
    const url = `/${getOrigin()}`;
    navigate(url);
  }

  function redirect() {
    if (ctvCode) return navigate(CTV_SUCCESS_PAGE_PATH);
    if (shouldOpenMenuAccount(redirectURL, { emailValidated: true }))
      return navigate('/menu-de-conta');
    redirectToSite();
  }

  return (
    <Container>
      <Card width="400px" minHeight="550px" center>
        <div className="flex flex-column">
          <LogoText />
          <h2 className="color-text-default">reativação de conta</h2>
          <span className="md mt-4 color-text-default">
            Você excluiu sua conta em {inactiveDate}.
            <br />
            <br /> Em {endDate} sua conta será excluída para sempre e não será
            possível recuperá-la. <br />
            <br /> Ao clicar em “reativar conta”, o processo de exclusão será
            cancelado e você poderá utilizar sua conta normalmente.
          </span>

          <div className="flex flex-column mt-40">
            <Button
              full
              loading={loading}
              onClick={sendReactivateAccount}
              disabled={
                !search.get('email') ||
                loading ||
                !statusAccountUser.accountInactiveSince ||
                statusAccountUser.accountActive
              }
            >
              <ButtonLabel loading={loading}>reativar conta</ButtonLabel>
            </Button>
            <Button
              className="mt-16"
              customColors="btn-white"
              full
              onClick={cancel}
              disabled={loading}
              loading={loading}
            >
              <ButtonLabel>cancelar</ButtonLabel>
            </Button>
          </div>
        </div>
      </Card>
    </Container>
  );
}
