import { createElement, useCallback, useMemo, useState } from 'react';
import Card from '../../../components/Card/Card';
import Container from '../../../components/Container/Container';
import Step from '../../../components/Wizard/Step';
import Wizard from '../../../components/Wizard/Wizard';
import AccountRecoveryProvider from '../../../data/AccountRecovery/AccountRecoveryContext';
import { SignUpContext } from '../../../data/SignUp/SignUpContext';
import AccessesRecovery from '../AccountRecoveryAccesses';
import RecoveryAccountCheckToken from '../AccountRecoveryCheckToken';
import EmailRecovery from '../AccountRecoveryEmail';

export interface AccountRecoveryProps {
  allowSkipStep?: boolean;
  updateData?: boolean;
  previousPath?: number | null;
  nextPath?: number | null;
  handleChangeStep(e: number): void;
  context: any;
  viewSuccess?: any;
}

export default function AccountRecoveryWizard() {
  const components: any = {
    EmailRecovery: {
      id: 'recuperar-conta/email',
      component: EmailRecovery,
      allowSkipStep: false
    },
    RecoveryAccountCheckToken: {
      id: 'recuperar-conta/token',
      component: RecoveryAccountCheckToken,
      allowSkipStep: false
    },
    AccessesRecovery: {
      id: 'recuperar-conta/informacoes-login',
      component: AccessesRecovery,
      allowSkipStep: false
    }
  };

  const [currentStep, setCurrentStep] = useState(0);
  const [defaultSteps] = useState([
    getComponent('EmailRecovery', false),
    getComponent('RecoveryAccountCheckToken', false),
    getComponent('AccessesRecovery', false)
  ]);

  const handleChangeStep = useCallback((indexStep: number) => {
    setCurrentStep(indexStep);
  }, []);

  function getComponent(key: string, allowSkipStep: boolean) {
    const component = components[key];
    component['allowSkipStep'] = allowSkipStep;
    return component;
  }

  const steps = useMemo(() => defaultSteps, [defaultSteps]);

  const buildSteps = useCallback(() => {
    const lastIndex = steps.length - 1;
    return steps.map((step, index) => {
      const component = createElement(step.component, {
        previousPath: index > 0 ? index - 1 : null,
        nextPath: index < lastIndex ? index + 1 : null,
        allowSkipStep: step.allowSkipStep,
        handleChangeStep,
        context: SignUpContext
      });
      return (
        <Step id={index} currentStep={currentStep} key={index}>
          {component}
        </Step>
      );
    });
  }, [currentStep, handleChangeStep, steps]);

  return (
    <AccountRecoveryProvider>
      <Container>
        <Card width="400px" minHeight="550px" center>
          <form className="width-full">
            <Wizard>{buildSteps()}</Wizard>
          </form>
        </Card>
      </Container>
    </AccountRecoveryProvider>
  );
}
