import './Loading.scss';
import { ReactComponent as LoadingIcon } from '../../assets/icons/loading.svg';

interface LoadingProps {
  color?: string;
}

export default function Loading({ color = '' }: LoadingProps) {
  return <LoadingIcon className={'loading ' + color} />;
}
