import { wait } from './Wait';

const KEY_RECAPTCHA = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY as string;

// This only works with Recaptcha V3
export async function executeRecaptcha(retry = 3): Promise<string | null> {
  const grecaptcha = (window as any).grecaptcha;

  if (retry === 0) return null;

  if (!grecaptcha) {
    await wait(250);
    return executeRecaptcha(retry--);
  }

  return new Promise((resolve, reject) => {
    grecaptcha.ready(async () => {
      try {
        const token = await grecaptcha.execute(KEY_RECAPTCHA, {
          action: 'submit'
        });
        resolve(token);
      } catch (e) {
        reject(e);
      }
    });
  });
}
