import { useEffect } from 'react';
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams
} from 'react-router-dom';
import { WEBVIEW_COOKIE } from '../data/Constants';
import { useAuthContext } from '../hooks/AuthContext';
import { setCookie } from '../services/cookieController';
import { pushGTMDataLayer } from '../utils/GenericFunctions';

const KEY_SESSION = process.env.REACT_APP_KEY_SESSION as string;
const KEY_REFRESH_SESSION = process.env.REACT_APP_KEY_REFRESH_SESSION as string;
const KEY_SESSION_ORIGIN = process.env.REACT_APP_KEY_SESSION_ORIGIN as string;

const TOKEN_URL_PARAM = 'token';
const JWT_URL_PARAM = 'jwt';
const REFRESH_TOKEN_URL_PARAM = 'refresh_token';
const ORIGIN_URL_PARAM = 'origin';

let previousPath: string | null = null;

const ProtectedRoute = () => {
  const { checkAuth, setOpenSession } = useAuthContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    const origin =
      searchParams.get(ORIGIN_URL_PARAM) || process.env.ORIGIN_DOMAIN_SBT_SITE;
    const token =
      searchParams.get(TOKEN_URL_PARAM) || searchParams.get(JWT_URL_PARAM);
    const refreshToken = searchParams.get(REFRESH_TOKEN_URL_PARAM);

    if (origin && token && refreshToken) {
      setCookie(KEY_SESSION, token);
      setCookie(KEY_REFRESH_SESSION, refreshToken);
      setCookie(KEY_SESSION_ORIGIN, origin);

      const webview = searchParams.get(WEBVIEW_COOKIE);
      if (webview === 'true') setCookie(WEBVIEW_COOKIE, 'true');

      searchParams.delete(TOKEN_URL_PARAM);
      searchParams.delete(REFRESH_TOKEN_URL_PARAM);
      searchParams.delete(ORIGIN_URL_PARAM);
      searchParams.delete(WEBVIEW_COOKIE);
      setSearchParams(searchParams);
    }

    setOpenSession(checkAuth(token || undefined)?.user);
  }, []);

  useEffect(() => {
    if (checkAuth(searchParams.get(TOKEN_URL_PARAM) || undefined)?.user) {
      if (previousPath !== location.pathname) pushGTMDataLayer();
      previousPath = location.pathname;
    }
  }, [location]);

  return checkAuth(searchParams.get(TOKEN_URL_PARAM) || undefined)?.user ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace />
  );
};

export default ProtectedRoute;
