export enum GENRE {
  MALE = 'MASCULINO',
  FEMALE = 'FEMININO',
  OTHER = 'OUTROS'
}
export enum PROVIDER_SOCIAL {
  FACEBOOK = 'FACEBOOK',
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE'
}

export const APP_ENV = process.env.REACT_APP_ENV || 'production';

export const LINK_HELP_PAGE =
  process.env.REACT_APP_LINK_HELP_PAGE || 'http://mais.sbt.com.br/ajuda';

export const LINK_TERMS = 'https://www.sbt.com.br/termos-de-uso';
export const LINK_VIDEOS_TERMS = 'https://www.sbtvideos.com.br/termos-de-uso';
export const LINK_PRIVACY_POLICY = 'https://www.sbtvideos.com.br/termos-de-uso';
export const LINK_SSO_NATIVE = 'https://conta.sbt.com.br';
export const LINK_CENTRAL_HELP = 'https://www.sbt.com.br/faq/16618';
export const LINK_SSO_SBT = 'https://conta.sbt.com.br/sbt';
export const LINK_SSO_VIDEOS = 'https://conta.sbt.com.br/videos';
export const LINK_VIDEOS_PRIVACY_POLICY =
  'https://www.sbtvideos.com.br/termos-de-uso';
export const SIGNIN_SUCCESS_PAGE_VALIDATE_EMAIL = 'VALIDATE_EMAIL';
export const SIGNIN_SUCCESS_PAGE_COMPLETE_DATA = 'COMPLETE_DATA';
export const CODE_EXCEPTIONAL_ERROR = 'SBT-ACCOUNT-0';
export const CODE_INCORRECT_TOKEN = 'SBT-ACCOUNT-1';
export const CODE_INVALID_CPF = 'SBT-ACCOUNT-2';
export const CODE_FIELD_EMPTY = 'SBT-ACCOUNT-3';
export const CODE_INACTIVE_ACCOUNT = 'SBT-ACCOUNT-4';
export const CODE_INVALID_TOKEN = 'SBT-ACCOUNT-5';
export const CODE_NOT_ACCOUNT_CREATED = 'SBT-ACCOUNT-6';
export const CODE_EMAIL_NOT_SKIP_VALIDATE = 'SBT-ACCOUNT-8';
export const CODE_INCORRECT_CREDENTIALS = 'SBT-ACCOUNT-10';
export const CODE_INCORRECT_ORIGIN = 'SBT-ACCOUNT-11';
export const CODE_TOKEN_IS_NOT_FOR_THE_USER = 'SBT-ACCOUNT-12';
export const CODE_EXPIRED_TOKEN_ACCOUNT = 'SBT-ACCOUNT-14';
export const CODE_INCORRECT_NONCE_TOKEN = 'SBT-SSO-1';
export const CODE_ERROR_CONNECT_INFO_STORE_DATA = 'SBT-SSO-2';
export const CODE_ERROR_CONNECT_SESSION = 'SBT-SSO-3';
export const CODE_NOT_EMAIL_LOGIN_SOCIAL = 'SBT-ACCOUNT-25';
export const CODE_NOT_EMAIL_SIGNIN_SOCIAL = 'SBT-SSO-5';
export const CODE_NOT_IMPLEMENTED_SSO = 'SBT-SSO-6';
export const CODE_NOT_ACCOUNT = 'SBT-SSO-8';
export const CODE_EXPIRED_TOKEN = 'SBT-SSO-9';
export const CODE_ACCOUNT_WITH_EMAIL_NOT_FOUND = 'SBT-SSO-13';
export const CODE_CREDENTIAL_WITH_NO_PASS = 'SBT-SSO-14';
export const SSO_SOCIAL_STATUS_MERGED = 'MERGED';
export const SSO_SOCIAL_STATUS_CREATED = 'CREATED';
export const SSO_SOCIAL_STATUS_JUST_LOGGED = 'JUST_LOGGED';
export const MERGE_KEY_FACEBOOK = 'faceId';
export const MERGE_KEY_APPLE = 'appleId';
export const MERGE_KEY_GOOGLE = 'googleId';
export const ORIGIN_DOMAIN_SBT_SITE = 'SBTSITE';
export const ORIGIN_DOMAIN_SBT_VIDEOS = 'SBTVIDEOS';
export const ORIGIN_DOMAIN_SBT_NEWS = 'SBTNEWS';
export const ORIGIN_DOMAIN_SBT_GAMES = 'SBTGAMES';
export const ORIGIN_DOMAIN_SBT_TV = 'SBTTV';
export const ORIGIN_DOMAIN_MAIS_SBT = 'MAISSBT';
export const ORIGIN_DOMAIN_SBT_NEGOCIOS = 'SBTNEGOCIOS';
export const DOMAIN_NATIVE_SBT = 'https://www.sbt.com.br';
export const DOMAIN_VIDEOS_SBT = 'https://www.sbtvideos.com.br';
export const PASS_MAX_LENGTH = '20';
export const WEBVIEW_COOKIE = 'webview';
export const CTV_CODE_COOKIE = 'Y3R2Y29kZQ';

export const CTV_SUCCESS_PAGE_PATH = '/ctv/sucesso';

export const SBT_VIDEOS_URL =
  process.env.REACT_APP_SBT_VIDEOS_URL || 'https://www.sbtvideos.com.br';
export const SBT_SITE_URL =
  process.env.REACT_APP_SBT_SITE_URL || 'https://sbt.com.br';
export const SBT_TV_URL =
  process.env.REACT_APP_SBT_TV_URL || 'https://tv.sbt.com.br/sso';
export const MAIS_SBT_URL =
  process.env.REACT_APP_MAIS_SBT_URL ||
  'https://mais.sbt.com.br/authentication';

export const DEFAULT_API_COOLDOWNS = [1 * 60, 3 * 60, 5 * 60, 15 * 60, 30 * 60];
