import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import ButtonLabel from '../../components/Button/ButtonLabel';
import FormFieldTokenNumber from '../../components/FormField/FormFieldTokenNumber';
import LogoText from '../../components/LogoText/LogoText';
import {
  CODE_INCORRECT_TOKEN,
  DEFAULT_API_COOLDOWNS
} from '../../data/Constants';
import { useControllerFlowStepsContext } from '../../hooks/ControllerFlowStepsContext';
import { ResponseTokenSocial } from '../../model/Models';
import { sendSocialData } from '../../services/socialNetWorkController';
import { validateToken } from '../../services/tokenValidation';
import { mergeAccount, updateTelephoneComplement } from '../../services/user';
import { convertSecondsToMMSS, isSignUp } from '../../utils/GenericFunctions';
import { ErrorMessages } from '../../data/ErrorMessages';
import { SentryContextsEnum } from '../../utils/Sentry';
import { AttemptsTypeEnum } from '../../enums/AttemptsTypeEnum';

export interface CheckTokenProps {
  allowSkipStep?: boolean;
  goBackView?: React.Dispatch<React.SetStateAction<boolean>>;
  callCreateToken(e: any): any;
  previousPath?: number | null;
  nextPath?: number | null;
  handleChangeStep(e: number): void;
  context: any;
  isEmail?: boolean;
  updatePhone?: boolean;
}

export default function CheckToken({
  goBackView,
  callCreateToken,
  nextPath,
  handleChangeStep,
  context,
  isEmail,
  updatePhone
}: CheckTokenProps) {
  const {
    socialData,
    setSocialData,
    socialWithoutEmail,
    setSocialWithoutEmail,
    setControllerViews,
    tokenSocial,
    notCreateAccount,
    providerSocial,
    showErrorMessageServer,
    attempts,
    increaseAttemptByType
  } = useControllerFlowStepsContext();
  const { getValues, handleSubmit, submitForm, originDomainForm, setValue } =
    useContext<any>(context);
  const [fieldValidate, setValueFieldValidate] = useState({
    value: '',
    isValid: false
  });
  const [errorToken, setErrorToken] = useState(false);
  const [resendingCode, setResendingCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [runTime, setRunTime] = useState(false);
  const url = `/${originDomainForm}/verifica-token`;

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    timeOutResend(attempts?.VALIDATE_EMAIL);
  }, []);

  function timeOutResend(intervalPos: number) {
    setRunTime(true);

    let initSeconds = DEFAULT_API_COOLDOWNS[intervalPos] || 60;
    setSeconds(initSeconds);
    const interval = setInterval(() => {
      if (initSeconds == 0) {
        clearInterval(interval);
        setSeconds(0);
        setRunTime(false);
      } else {
        setSeconds(--initSeconds);
      }
    }, 1000);
  }

  useEffect(() => {
    navigate(url, { replace: true });
  }, [navigate]);

  function previousStep() {
    if (goBackView) goBackView(true);
  }

  async function checkToken() {
    let valueKey;
    if (isEmail) {
      valueKey = socialData.email || getValues('email');
    } else {
      valueKey = getValues('cellPhoneNumber');
      valueKey = valueKey?.replace(/\D/g, '');
    }

    const code = fieldValidate.value;

    if (valueKey && code) {
      setLoading(true);
      try {
        const emailErrorMessage = ErrorMessages.CODE_EMAIL_VALIDATE_ACCOUNT;
        if (providerSocial)
          emailErrorMessage.context = SentryContextsEnum.SOCIAL_SIGNUP;

        await validateToken(
          valueKey,
          code,
          isEmail ? emailErrorMessage : ErrorMessages.CODE_VALIDATE_PHONE
        );

        setValue('emailCode', code);

        if (updatePhone) {
          const formatValue = valueKey.replace(/\D/g, '');
          await updateTelephoneComplement(
            socialData.email || getValues('email'),
            formatValue
          );
          nextStep();
        } else {
          if (notCreateAccount && !socialWithoutEmail) {
            nextStep();
          } else if (socialWithoutEmail) {
            sendSocialToken();
          } else {
            createAccount();
          }
        }
      } catch (error: any) {
        console.log('error validate token');
        if (error?.code === CODE_INCORRECT_TOKEN) {
          setErrorToken(true);
        } else {
          showErrorMessageServer();
        }
        setResendingCode(false);
        setLoading(false);
      }
    }
  }

  async function sendSocialToken() {
    const token = tokenSocial;
    const email = getValues('email');
    try {
      const resp: ResponseTokenSocial = await sendSocialData(
        token,
        providerSocial,
        email
      );
      setSocialData({ ...resp, userName: socialData?.userName });
      setValue('provider', providerSocial);
    } catch (error: any) {
      console.log(error);
      if (error?.statusCode === 404) await handleMergeAccount();
    }
  }

  async function handleMergeAccount() {
    try {
      await mergeAccount({
        provider: providerSocial,
        providerToken: tokenSocial,

        email: getValues('email'),
        emailCode: fieldValidate.value
      });
      setControllerViews({ mergeAccount: true });
    } catch (error: any) {
      if (error?.statusCode === 404) {
        if (socialWithoutEmail) {
          setSocialWithoutEmail(false);
          nextStep();
        } else {
          createAccount();
        }
      } else {
        setLoading(false);
        showErrorMessageServer();
      }
    }
  }

  async function resendCode() {
    setErrorToken(false);
    setResendingCode(true);
    setLoading(true);
    await callCreateToken(true);
    increaseAttemptByType(AttemptsTypeEnum.VALIDATE_EMAIL);
    timeOutResend(attempts.VALIDATE_EMAIL + 1);
    setLoading(false);
  }

  async function createAccount() {
    if (providerSocial) {
      setValue('isSocial', true);
      setValue('provider', providerSocial);
      if (socialData.email) setValue('email', socialData.email);

      handleSubmit((data: any) => submitForm(data, nextStep, providerSocial))();
    } else {
      handleSubmit((data: any) => submitForm(data, nextStep))();
    }
  }

  function getPhoneNumber() {
    const phone = getValues('cellPhoneNumber');
    if (!phone) return '';
    try {
      const [areaCode, number] = phone.split(' ');
      const splitNumber = number.split('-');
      return `${areaCode} XXXXX-${splitNumber[1]}`;
    } catch (error) {
      return '';
    }
  }

  function nextStep() {
    if (nextPath) {
      handleChangeStep(nextPath);
    }
  }

  return (
    <div className="flex flex-column">
      <LogoText />
      <h2 className="color-text-default">
        {isEmail
          ? 'valide seu e-mail'
          : isSignUp(originDomainForm)
          ? 'criar conta'
          : 'complete seus dados'}
      </h2>
      <h6 className="mti-8 mbi-8 color-text-default">
        Informe o código de validação
      </h6>
      {isEmail ? (
        <span className="md mt-4 mb-24 color-text-default">
          Digite o código enviado para o e-mail
          <br />
          <span className="md-bold-700">
            {socialData.email || getValues('email')}
          </span>
          {(originDomainForm === 'signup' ||
            originDomainForm === 'videos/signup' ||
            originDomainForm === 'sbt/signup') &&
          !location.pathname?.includes('signup') ? (
            <span className="link ml-6" onClick={previousStep}>
              alterar e-mail
            </span>
          ) : null}
        </span>
      ) : (
        <span className="md mt-4 mb-24">
          Digite o código recebido por SMS no número
          <br />
          <span className="md-bold-700">+55 {getPhoneNumber()}</span>
          <span className="link ml-6" onClick={previousStep}>
            alterar número
          </span>
        </span>
      )}

      <FormFieldTokenNumber
        fieldValidate={fieldValidate}
        errorToken={errorToken}
        setState={setValueFieldValidate}
        resendingCode={resendingCode}
      />

      <div className="flex flex-column mt-16">
        <Button
          className="mt-8 mbi-0"
          full
          onClick={checkToken}
          loading={loading}
          disabled={!fieldValidate.isValid}
        >
          <ButtonLabel loading={loading}>confirmar código</ButtonLabel>
        </Button>
        <Button
          className="mt-16 mbi-0"
          customColors="btn-outline"
          full
          onClick={resendCode}
          loading={loading}
          disabled={runTime}
        >
          <ButtonLabel>
            reenviar código {convertSecondsToMMSS(seconds)}
          </ButtonLabel>
        </Button>
        <Button
          className="mt-16"
          customColors="btn-white"
          full
          onClick={previousStep}
        >
          <ButtonLabel>voltar</ButtonLabel>
        </Button>
      </div>
    </div>
  );
}
