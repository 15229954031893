import * as Sentry from '@sentry/react';
import { IErrorMessage } from '../data/ErrorMessages';
import { readJwt } from '../services/tokenValidation';

export enum SentryContextsEnum {
  SIGNIN = 'signIn',
  SIGNUP = 'signUp',
  SOCIAL_SIGNUP = 'socialSignUp',
  FORGOT_PASSWORD = 'forgotPassword',
  FORGOT_ACCOUNT = 'forgotAccount',
  REFRESH_TOKEN = 'refreshToken',
  TERMS = 'fetchTerms',

  ACCOUNT = 'account',
  CREATE_PASSWORD = 'createPassword',
  CHANGE_PASSWORD = 'changePassword',
  ADD_PHONE = 'addPhone',
  CHANGE_PHONE = 'changePhone',

  CHANGE_COMMUNICATION_PREFERENCES = 'changeCommunicationPreferences',
  DELETE_ACCOUNT = 'accountDelete',
  ACCOUNT_MENU_MERGE = 'accountMenuMerge'
}

const CELLPHONE_REGEX =
  /^\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/;

function extractPhoneFromURL(url: string): string | null {
  const match = url.match(CELLPHONE_REGEX);
  return match?.[1] || null;
}

function extractPhoneFromPayload(body: any): string | null {
  const value = body?.key || body?.cellphone;
  if (CELLPHONE_REGEX.test(value)) return value;
  return null;
}

export function sendSentryMessage(
  customErrorMessage: IErrorMessage,
  error?: any,
  level: Sentry.SeverityLevel = 'info'
) {
  try {
    const requestData = JSON.parse(error?.config?.data);

    // Handle Request E-mail
    let email = requestData?.email;
    if (!email) {
      const jwt = error?.config?.headers?.Authorization?.split('Bearer ')?.[1];
      const decodedToken: any = readJwt(jwt);
      email = decodedToken?.user?.email;
    }

    // Handle Request Cellphone
    // Must get on payload or URL
    const cellphone =
      extractPhoneFromURL(error?.config?.url) ||
      extractPhoneFromPayload(requestData);

    // Handle Sentry User
    const user: Sentry.User = {};
    if (email) user.email = email;
    if (cellphone) user.cellphone = cellphone;

    console.log(
      'Mensagem customizada do sentry enviada:',
      customErrorMessage.message
    );
    Sentry.captureMessage(customErrorMessage.message, {
      level,
      contexts: {
        response: error?.response?.data || error
      },
      tags: {
        requestUrl: error?.request?.responseURL,
        context: customErrorMessage.context,
        ...customErrorMessage?.extras
      },
      ...(Object.keys(user)?.length && { user })
    });
  } catch (e) {
    console.error(e);
  }
}
