import { FieldErrorsImpl } from 'react-hook-form';
import { cpfMask } from '../../utils/Masks';
import FormField from '../FormField/FormField';

interface CpfComponentProps {
  errors: Partial<FieldErrorsImpl<any>>;
  name: string;
  register: any;
  touchedFields: any;
  disabled?: boolean;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
}

export default function CpfComponent({
  name,
  errors,
  register,
  touchedFields,
  disabled,
  onBlur
}: CpfComponentProps) {
  return (
    <FormField
      type="text"
      name={name}
      label="CPF"
      error={errors}
      disabled={disabled}
      sufix={disabled}
      iconSufix={'lock'}
      touchedFields={touchedFields}
      register={register(name, { onChange: cpfMask })}
      placeholder="digite seu CPF"
      inputMode="numeric"
      onBlur={onBlur}
    />
  );
}
