import * as yup from 'yup';
import {
  schemaEmailRequired,
  schemaStringPhoneRequired,
  schemaCheckPasswordRequired,
  schemaPasswordRequired
} from '../SchemaFieldsRequired';

export interface FormAccountRecoveryValues {
  email: string;
  cellPhoneNumber: string;
  password: string;
  checkPassword: string;
  code?: string;
  isSocial?: boolean;
}

export function createForm() {
  const objectFiels = {
    email: schemaEmailRequired,
    cellPhoneNumber: schemaStringPhoneRequired,
    password: schemaPasswordRequired,
    checkPassword: schemaCheckPasswordRequired
  };

  return yup.object(objectFiels);
}
