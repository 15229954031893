import { ErrorMessages } from '../data/ErrorMessages';
import { getOriginDomain } from '../utils/GenericFunctions';
import { DELETE, PATCH, POST } from './api';

const ACCOUNT_SERVICE = process.env.REACT_APP_BASE_URL_ACCOUNT_SERVICE;

export async function checkPendingData(email: string) {
  const response = await POST(
    `${ACCOUNT_SERVICE}/api/v1/account/login`,
    {
      email,
      origin: getOriginDomain()
    },
    ErrorMessages.ACCOUNT_STATUS
  );
  return response.data;
}

export async function reactivateAccount(email: string) {
  const response = await POST(`${ACCOUNT_SERVICE}/api/v1/account`, {
    email
  });
  return response.data;
}

export async function updateAccountInformation(
  email: string,
  values: object,
  customErrorMessage = ErrorMessages.UPDATE_ACCOUNT_DATA
) {
  const response = await PATCH(
    `${ACCOUNT_SERVICE}/api/v1/user`,
    values,
    customErrorMessage
  );
  return response.data;
}

export async function unlinkSocialAccount(email: string, provider: string) {
  const response = await DELETE(
    `${ACCOUNT_SERVICE}/api/v1/social/${email}`,
    {
      data: { provider }
    },
    ErrorMessages.DELETE_SOCIAL_MERGE
  );
  return response.data;
}
