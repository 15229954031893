import {
  createElement,
  useCallback,
  useState,
  useMemo,
  useEffect
} from 'react';
import Card from '../../components/Card/Card';
import Container from '../../components/Container/Container';
import Wizard from '../../components/Wizard/Wizard';
import Step from '../../components/Wizard/Step';
import './ForgetPasswordWizard.scss';
import PasswordRecoveryEmail from '../PasswordRecovery/PasswordRecoveryEmail';
import PasswordRecoveryCheckToken from '../PasswordRecovery/PasswordRecoveryCheckToken';
import PasswordRecoveryWithoutPassword from '../PasswordRecovery/PasswordRecoveryWithoutPassword';
import PasswordRecoverySetPassword from '../PasswordRecovery/PasswordRecoverySetPassword';
import PasswordRecoverySuccessfully from '../PasswordRecovery/PasswordRecoverySuccessfully';
import AccountRecoveryProvider, {
  AccountRecoveryContext
} from '../../data/AccountRecovery/AccountRecoveryContext';
import { useControllerFlowStepsContext } from '../../hooks/ControllerFlowStepsContext';

export default function ForgetPasswordWizard() {
  const components: any = {
    PasswordRecoveryEmail: {
      id: 'password-recovery-email',
      component: PasswordRecoveryEmail
    },
    PasswordRecoveryCheckToken: {
      id: 'password-recovery-check-token',
      component: PasswordRecoveryCheckToken
    },
    PasswordRecoveryWithoutPassword: {
      id: 'password-recovery-without-password',
      component: PasswordRecoveryWithoutPassword,
      allowSkipStep: true
    },
    PasswordRecoverySetPassword: {
      id: 'password-recovery-set-password',
      component: PasswordRecoverySetPassword
    },
    PasswordRecoverySuccessfully: {
      id: 'password-recovery-successfully',
      component: PasswordRecoverySuccessfully
    }
  };
  const { controllerViews } = useControllerFlowStepsContext();
  const [currentStep, setCurrentStep] = useState(0);
  const initialSteps = [getComponent('PasswordRecoveryEmail')];
  const [defaultSteps, setDefaultSteps] = useState(initialSteps);

  const handleChangeStep = useCallback((indexStep: number) => {
    setCurrentStep(indexStep);
  }, []);

  useEffect(() => {
    controllerViewsPage();
  }, [controllerViews]);

  function controllerViewsPage() {
    const updateSteps = initialSteps;

    updateSteps.push(getComponent('PasswordRecoveryCheckToken'));

    if (!controllerViews.hasPassword) {
      updateSteps.push(getComponent('PasswordRecoveryWithoutPassword'));
    } else {
      updateSteps.push(getComponent('PasswordRecoverySetPassword'));
      updateSteps.push(getComponent('PasswordRecoverySuccessfully'));
    }

    setDefaultSteps(updateSteps);
  }

  useEffect(() => {
    if (Object.keys(controllerViews).length) {
      console.log(controllerViews.recoveryEmail);
    }
  }, [controllerViews]);

  function getComponent(key: string) {
    const component = components[key];
    return component;
  }

  const steps = useMemo(() => defaultSteps, [defaultSteps]);
  const buildSteps = useCallback(() => {
    const lastIndex = steps.length - 1;
    return steps.map((step, index) => {
      const component = createElement(step.component, {
        previousPath: index > 0 ? index - 1 : null,
        nextPath: index < lastIndex ? index + 1 : null,
        handleChangeStep,
        context: AccountRecoveryContext
      });
      return (
        <Step id={index} currentStep={currentStep} key={index}>
          {component}
        </Step>
      );
    });
  }, [currentStep, handleChangeStep, steps]);

  return (
    <AccountRecoveryProvider>
      <Container>
        <Card width="400px" minHeight="550px" center>
          <form className="width-full" onSubmit={(e) => e.preventDefault()}>
            <Wizard>{buildSteps()}</Wizard>
          </form>
        </Card>
      </Container>
    </AccountRecoveryProvider>
  );
}
