import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowLeft2 } from '../../assets/icons/arrow-left2.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { ReactComponent as CloseRounded } from '../../assets/icons/close-rounded.svg';
import { ReactComponent as AccountData } from '../../assets/icons/accountData.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as Device } from '../../assets/icons/device.svg';
import { ReactComponent as Download } from '../../assets/icons/download.svg';
import { ReactComponent as EditPencil } from '../../assets/icons/edit-pencil.svg';
import { ReactComponent as EmailApprove } from '../../assets/icons/email-approve.svg';
import { ReactComponent as EmailError } from '../../assets/icons/email-error.svg';
import { ReactComponent as Error } from '../../assets/icons/error.svg';
import { ReactComponent as ErrorOutlined } from '../../assets/icons/error-outlined.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import { ReactComponent as EyeClose } from '../../assets/icons/eye-close.svg';
import { ReactComponent as FaceSad } from '../../assets/icons/face-sad.svg';
import { ReactComponent as FlagBrasil } from '../../assets/icons/flag-brasil.svg';
import { ReactComponent as IconPlus } from '../../assets/icons/iconPlus.svg';
import { ReactComponent as Interrogation } from '../../assets/icons/interrogation.svg';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import { ReactComponent as InfoOutlined } from '../../assets/icons/info-outlined.svg';
import { ReactComponent as Lock } from '../../assets/icons/lock.svg';
import { ReactComponent as Loading } from '../../assets/icons/loading.svg';
import { ReactComponent as LogoApple } from '../../assets/icons/logo-apple.svg';
import { ReactComponent as LogoFace } from '../../assets/icons/logo-facebook.svg';
import { ReactComponent as LogoGoogle } from '../../assets/icons/logo-google.svg';
import { ReactComponent as LogoSbt } from '../../assets/icons/logo-sbt.svg';
import { ReactComponent as NewTab } from '../../assets/icons/new-tab.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { ReactComponent as Setup } from '../../assets/icons/setup.svg';
import { ReactComponent as Success } from '../../assets/icons/success.svg';
import { ReactComponent as SuccessOutlined } from '../../assets/icons/success-outlined.svg';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';
import { ReactComponent as TrashButton } from '../../assets/icons/trash-button.svg';
import { ReactComponent as TriangleExclamation } from '../../assets/icons/triangle-exclamation.svg';
import { ReactComponent as CheckedSuccessfully } from '../../assets/icons/checked-successfully.svg';
import { ReactComponent as Vector } from '../../assets/icons/vector.svg';
import { ReactComponent as Chrome } from '../../assets/icons/chrome.svg';
import { ReactComponent as Edge } from '../../assets/icons/edge.svg';
import { ReactComponent as Firefox } from '../../assets/icons/Firefox.svg';
import { ReactComponent as Unknown } from '../../assets/icons/unknown.svg';
import { ReactComponent as Caution } from '../../assets/icons/caution.svg';
import { ReactComponent as Opera } from '../../assets/icons/opera.svg';
import { ReactComponent as Safari } from '../../assets/icons/safari.svg';

type IconProps = { name: string };
type IconTypes = { [name: string]: React.FunctionComponent };

const iconTypes: IconTypes = {
  arrowDown: ArrowDown,
  arrowUp: ArrowUp,
  arrowLeft: ArrowLeft,
  arrowLeft2: ArrowLeft2,
  arrowRight: ArrowRight,
  close: Close,
  closeRounded: CloseRounded,
  accountData: AccountData,
  delete: Delete,
  device: Device,
  download: Download,
  editPencil: EditPencil,
  emailApprove: EmailApprove,
  emailError: EmailError,
  error: Error,
  errorOutlined: ErrorOutlined,
  eye: Eye,
  eyeClose: EyeClose,
  faceSad: FaceSad,
  flagBrasil: FlagBrasil,
  iconPlus: IconPlus,
  interrogation: Interrogation,
  info: Info,
  infoOutlined: InfoOutlined,
  lock: Lock,
  loading: Loading,
  logoApple: LogoApple,
  logoFace: LogoFace,
  logoGoogle: LogoGoogle,
  logoSbt: LogoSbt,
  newTab: NewTab,
  plus: Plus,
  setup: Setup,
  success: Success,
  successOutlined: SuccessOutlined,
  trash: Trash,
  trashButton: TrashButton,
  triangleExclamation: TriangleExclamation,
  checkedSuccessfully: CheckedSuccessfully,
  vector: Vector,
  chrome: Chrome,
  edge: Edge,
  firefox: Firefox,
  unknown: Unknown,
  caution: Caution,
  opera: Opera,
  safari: Safari
};

const Icon = ({ name, ...props }: IconProps | any) => {
  const Icon = iconTypes[name];
  return <Icon {...props} />;
};

export default Icon;
