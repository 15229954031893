interface IGoogleUserInfo {
  id: string;
  email: string;
  name: string;
  given_name: string;
  family_name: string;
}

export async function getGoogleUserInfo(token: string) {
  const response = await new Promise((resolve) => {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', 'https://www.googleapis.com/oauth2/v3/userinfo');
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300)
        resolve(JSON.parse(this.responseText));
      else resolve({ err: '404' });
    };
    xhr.send();
  });

  return response as IGoogleUserInfo;
}
