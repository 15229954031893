import './Modal.scss';
import Icon from '../Icon/Icon';

interface ModalIconProps {
  icon: string;
  color?: string;
}

export default function ModalIcon({ icon, color }: ModalIconProps) {
  return (
    <div className={`modal-icon ${color}`}>
      <Icon name={icon} />
    </div>
  );
}
