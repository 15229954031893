import { createContext, useContext, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ModalHandles } from '../../components/Modal/Modal';
import { UseWindowSize } from '../../hooks/ResizeWindow';

export interface ISubjectsAnnouncementsItem {
  title: string;
  value: string;
  active: boolean;
}

export interface ITypesCommunicate {
  email: boolean;
  sms: boolean;
  whatsapp: boolean;
}

export interface ManagerPreferencesContextData {
  modalRef: React.RefObject<ModalHandles>;
  sizeView: any;
  setSelectedLink: React.Dispatch<React.SetStateAction<number>>;
  selectedLink: number;
  setFormEqual: React.Dispatch<any>;
  isFormEqual: any;
  setshowModalPendingDataToSave: React.Dispatch<React.SetStateAction<boolean>>;
  showModalPendingDataToSave: boolean;
  openModalPendingDataToSave(): void;

  setcheckTypeCommunicate: React.Dispatch<
    React.SetStateAction<ITypesCommunicate>
  >;
  checkTypeCommunicate: ITypesCommunicate;
  setcheckTypeSubjectsAnnouncements: React.Dispatch<
    React.SetStateAction<Array<ISubjectsAnnouncementsItem>>
  >;
  checkTypeSubjectsAnnouncements: Array<ISubjectsAnnouncementsItem>;
  copySubjectsAnnouncements(data: Array<ISubjectsAnnouncementsItem>): void;
  copyAnnouncements: any;
  copyTypesCommunicate(data: ITypesCommunicate): void;
  copyTypeCommunicate: ITypesCommunicate;
}

export const ManagerPreferencesContext = createContext({} as any);

const ManagerPreferencesProvider = () => {
  const [checkTypeCommunicate, setcheckTypeCommunicate] =
    useState<ITypesCommunicate>({
      email: false,
      sms: false,
      whatsapp: false
    } as ITypesCommunicate);

  const [checkTypeSubjectsAnnouncements, setcheckTypeSubjectsAnnouncements] =
    useState<Array<ISubjectsAnnouncementsItem>>([
      { title: 'Programação da TV', value: 'tvSchedule', active: false },
      { title: 'Conteúdo online', value: 'onlineContent', active: false },
      { title: '+SBT', value: 'maisSbt', active: false },
      { title: 'SBT News', value: 'sbtNews', active: false },
      { title: 'SBT Games', value: 'sbtGames', active: false },
      { title: 'SBT Sports', value: 'sbtSports', active: false },
      { title: 'Produtos SBT', value: 'sbtProducts', active: false },
      { title: 'Promoções ', value: 'promotions', active: false },
      { title: 'Parceiros do SBT', value: 'sbtPartners', active: false },
      { title: 'Institucional', value: 'institutional', active: false },
      { title: 'Pesquisas', value: 'researches', active: false }
    ]);

  const [copyTypeCommunicate, setCopyTypeCommunicate] = useState(null);
  const [copyAnnouncements, setCopyAnnouncements] = useState(null);
  const sizeView: any = UseWindowSize();
  const [selectedLink, setSelectedLink] = useState(0);
  const [showModalPendingDataToSave, setshowModalPendingDataToSave] =
    useState(false);

  const [isFormEqual, setFormEqual] = useState({
    equal: true,
    fieldsNotEqual: []
  } as any);

  const modalRef = useRef<ModalHandles>(null);
  function openModalDeletePhone() {
    modalRef.current?.openModal();
  }

  function openModalPendingDataToSave() {
    modalRef.current?.openModal();
  }

  function copySubjectsAnnouncements(data: Array<ISubjectsAnnouncementsItem>) {
    setCopyAnnouncements(JSON.parse(JSON.stringify(data)));
  }

  function copyTypesCommunicate(data: ITypesCommunicate) {
    setCopyTypeCommunicate(JSON.parse(JSON.stringify(data)));
  }

  return (
    <ManagerPreferencesContext.Provider
      value={{
        modalRef,
        openModalDeletePhone,
        sizeView,
        selectedLink,
        setSelectedLink,
        isFormEqual,
        setFormEqual,
        showModalPendingDataToSave,
        setshowModalPendingDataToSave,
        openModalPendingDataToSave,

        checkTypeCommunicate,
        setcheckTypeCommunicate,
        checkTypeSubjectsAnnouncements,
        setcheckTypeSubjectsAnnouncements,
        copyAnnouncements,
        copySubjectsAnnouncements,
        copyTypeCommunicate,
        copyTypesCommunicate
      }}
    >
      <Outlet />
    </ManagerPreferencesContext.Provider>
  );
};
export const useManagerPreferencesContext = () =>
  useContext<ManagerPreferencesContextData>(ManagerPreferencesContext);
export default ManagerPreferencesProvider;
