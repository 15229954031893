import { useEffect, useState } from 'react';

import Button from '../../../components/Button/Button';
import ButtonLabel from '../../../components/Button/ButtonLabel';

import './CTVCheckCode.scss';
import FormFieldCodeSimple from '../../../components/FormField/FormFieldCodeSimple';
import CTVContainer from '../../../components/CTVContainer';
import {
  CODE_INCORRECT_TOKEN,
  CTV_CODE_COOKIE,
  ORIGIN_DOMAIN_MAIS_SBT
} from '../../../data/Constants';
import { useControllerFlowStepsContext } from '../../../hooks/ControllerFlowStepsContext';
import { validateCTVCode } from '../../../services/ctv';
import { useNavigate } from 'react-router-dom';
import Icon from '../../../components/Icon/Icon';
import { KEY_SESSION_ORIGIN } from '../../../utils/GenericFunctions';
import { setCookie } from '../../../services/cookieController';

export default function CTVCheckCode() {
  const [errorToken, setErrorToken] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fieldValidate, setValueFieldValidate] = useState({
    value: '',
    isValid: false
  });

  const { setCtvCode, showErrorMessageServer } =
    useControllerFlowStepsContext();

  const navigate = useNavigate();

  async function checkCode() {
    if (fieldValidate.value) {
      setIsLoading(true);
      try {
        await validateCTVCode(fieldValidate.value);
        setCtvCode(fieldValidate.value);
        setCookie(CTV_CODE_COOKIE, fieldValidate.value, {
          expires: 1 / 288 // 5 minutes
        });

        if (window.location.pathname === '/entrar') navigate('/signin');
        else navigate('/signup');
      } catch (error: any) {
        if (error?.code === CODE_INCORRECT_TOKEN) {
          setErrorToken(true);
        } else {
          showErrorMessageServer();
        }
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    // +SBT CTV Flow, always set origin to MAISSBT
    setCookie(KEY_SESSION_ORIGIN, ORIGIN_DOMAIN_MAIS_SBT);
  }, []);

  return (
    <CTVContainer>
      <div className="ctv-card">
        {errorToken ? (
          <>
            <Icon className="error-title" name="errorOutlined" />
            <h2 className="ctv-title">Código incorreto</h2>
            <p className="ctv-error-message">Tente novamente.</p>
          </>
        ) : (
          <h2 className="ctv-title">
            Informe o código de 8 dígitos que apareceu na sua TV.
          </h2>
        )}
        <FormFieldCodeSimple
          fieldValidate={fieldValidate}
          errorToken={errorToken}
          setState={setValueFieldValidate}
        />
        <div className="button-container flex flex-column mt-16">
          <Button
            className="mt-8 mbi-0"
            full
            onClick={checkCode}
            loading={isLoading}
            disabled={!fieldValidate.isValid}
          >
            <ButtonLabel loading={isLoading}>avançar</ButtonLabel>
          </Button>
        </div>
      </div>
    </CTVContainer>
  );
}
