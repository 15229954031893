import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import ControllerFlowStepsProvider from './hooks/ControllerFlowStepsContext';
import Error from './pages/Error';
import ReactiveAccount from './pages/ReactiveAccount';
import SignInWizard from './pages/SignInWizard';
import SignUpNativo from './pages/SignUpNativo';
import ForgetPasswordWizard from './pages/ForgetPasswordWizard';
import ValidateEmailLink from './pages/ValidateEmailLink';
import AccountRecoveryWizard from './pages/AccountRecovery/AccountRecoveryWizard';
import AccountMenu from './pages/MenuAccount';
import ProtectedRoute from './auth/ProtectedRoute';
import DisableAccount from './pages/MenuAccount/DisableAccount/DisableAccount';
import OptionsAccount from './pages/MenuAccount/OptionsAccount';
import SocialMediaProvider from './hooks/ControllerSocialMedia';
import UpdateUserData from './pages/MenuAccount/UpdateUserData/UpdateUserData';
import UpdateUserDataProvider from './data/UpdateUserData/UpdateUserDataContext';
import CreatePassword from './pages/MenuAccount/CreatePassword';
import AccountRecoveryProvider from './data/AccountRecovery/AccountRecoveryContext';
import RegisterPhoneWizard from './pages/MenuAccount/UpdateUserData/RegisterPhoneWizard/RegisterPhoneWizard';
import EditPassword from './pages/MenuAccount/UpdateUserData/EditPassword/EditPassword';
import UserCreatePassword from './pages/MenuAccount/UpdateUserData/UserCreatePassword/UserCreatePassoword';
import NavBar from './components/NavBar/NavBar';
import { useAuthContext } from './hooks/AuthContext';
import { useState, useEffect } from 'react';
import CardAccountData from './pages/MenuAccount/UpdateUserData/CardAccount/CardAccount';
import CardAddressData from './pages/MenuAccount/UpdateUserData/CardAddress/CardAddress';
import CardPersonalData from './pages/MenuAccount/UpdateUserData/CardPersonalData/CardPersonalData';
import ManageCommunicationPreferences from './pages/MenuAccount/ManageCommunicationPreferences/ManageCommunicationPreferences';
import ManagerPreferencesProvider from './data/ManagerPreferences/ManagerPreferences';
import CardTypeCommunicate from './pages/MenuAccount/ManageCommunicationPreferences/CardTypeCommunicate/CardTypeCommunicate';
import CardSubjectCommunicate from './pages/MenuAccount/ManageCommunicationPreferences/CardSubjectCommunicate/CardSubjectCommunicate';
import CardNotifications from './pages/MenuAccount/ManageCommunicationPreferences/CardNotifications/CardNotifications';
import { isWebview } from './utils/GenericFunctions';
import TermsAndPolicy, { TERMS_POLICY_TYPES } from './pages/TermsPolicy';
import CTVCheckCode from './pages/CTV/CTVCheckCode';
import CTVSuccess from './pages/CTV/CTVSuccess';
import GoogleRedirect from './pages/OAuthRedirect/GoogleRedirect';
import FacebookRedirect from './pages/OAuthRedirect/FacebookRedirect';
import AppleRedirect from './pages/OAuthRedirect/AppleRedirect';

export function AppRoutes() {
  const [showNavbar, setShowNavbar] = useState(true);

  const { openSession } = useAuthContext();

  useEffect(() => {
    const elem = document.querySelector('body') as HTMLElement;
    if (openSession) {
      elem.classList.add('openedSess');
    } else {
      elem.classList.remove('openedSess');
    }

    if (isWebview()) {
      setShowNavbar(false);
      document.body.classList.add('webview');
    }
  }, [openSession]);

  return (
    <BrowserRouter>
      {openSession && showNavbar ? <NavBar /> : null}
      <ControllerFlowStepsProvider>
        <SocialMediaProvider>
          <Routes>
            <Route
              path="termos-de-uso"
              element={<TermsAndPolicy type={TERMS_POLICY_TYPES.TERMS} />}
            />
            <Route
              path="politica-de-privacidade"
              element={<TermsAndPolicy type={TERMS_POLICY_TYPES.POLICY} />}
            />

            <Route path="callback/oauth/google" element={<GoogleRedirect />} />
            <Route
              path="callback/oauth/facebook"
              element={<FacebookRedirect />}
            />
            <Route path="callback/oauth/apple" element={<AppleRedirect />} />

            <Route path="entrar" element={<CTVCheckCode />} />
            <Route path="cadastrar" element={<CTVCheckCode />} />
            <Route path="ctv/sucesso" element={<CTVSuccess />} />

            <Route path="signup" element={<SignUpNativo />} />
            <Route path="signup/:step" element={<SignUpNativo />} />

            <Route path="videos/signup" element={<SignUpNativo />} />
            <Route path="videos/signup/:step" element={<SignUpNativo />} />

            <Route path="sbt/signup" element={<SignUpNativo />} />
            <Route path="sbt/signup/:step" element={<SignUpNativo />} />

            <Route path="" element={<SignInWizard />}>
              <Route path="signin" element={<SignInWizard />} />
              <Route path="signin/:step" element={<SignInWizard />} />
            </Route>

            <Route path="videos" element={<SignInWizard />} />
            <Route path="videos/signin" element={<SignInWizard />} />
            <Route path="videos/signin/:step" element={<SignInWizard />} />

            <Route path="sbt" element={<SignInWizard />} />
            <Route path="sbt/signin" element={<SignInWizard />} />
            <Route path="sbt/signin/:step" element={<SignInWizard />} />

            <Route path="recuperar-senha" element={<ForgetPasswordWizard />} />
            <Route
              path="recuperar-senha/:step"
              element={<ForgetPasswordWizard />}
            />
            <Route
              path="videos/recuperar-senha"
              element={<ForgetPasswordWizard />}
            />
            <Route
              path="videos/recuperar-senha/:step"
              element={<ForgetPasswordWizard />}
            />
            <Route
              path="sbt/recuperar-senha"
              element={<ForgetPasswordWizard />}
            />
            <Route
              path="sbt/recuperar-senha/:step"
              element={<ForgetPasswordWizard />}
            />

            <Route path="recuperar-conta" element={<AccountRecoveryWizard />} />
            <Route
              path="recuperar-conta/:step"
              element={<AccountRecoveryWizard />}
            />
            <Route
              path="videos/recuperar-conta"
              element={<AccountRecoveryWizard />}
            />
            <Route
              path="videos/recuperar-conta/:step"
              element={<AccountRecoveryWizard />}
            />
            <Route
              path="sbt/recuperar-conta"
              element={<AccountRecoveryWizard />}
            />
            <Route
              path="sbt/recuperar-conta/:step"
              element={<AccountRecoveryWizard />}
            />
            <Route path="reativar-conta" element={<ReactiveAccount />} />
            <Route path="videos/reativar-conta" element={<ReactiveAccount />} />
            <Route path="sbt/reativar-conta" element={<ReactiveAccount />} />
            <Route path="validar-email" element={<ValidateEmailLink />} />

            <Route element={<ProtectedRoute />}>
              <Route path="menu-de-conta" element={<AccountMenu />}>
                <Route path="" element={<OptionsAccount />} />
                <Route path="excluir-conta" element={<DisableAccount />} />

                <Route
                  path="desativar-conta"
                  element={
                    <Navigate
                      to="/menu-de-conta/excluir-conta"
                      replace={true}
                    />
                  }
                />
              </Route>

              <Route
                path="menu-de-conta/criar-senha"
                element={
                  <AccountRecoveryProvider>
                    <CreatePassword />
                  </AccountRecoveryProvider>
                }
              />

              {/* <Route
                path="menu-de-conta/gerenciar-sessoes-ativas"
                element={<ManageActiveSessions />}
              /> */}
              <Route
                path="menu-de-conta/atualizar-dados"
                element={<UpdateUserDataProvider />}
              >
                <Route path="" element={<UpdateUserData />}>
                  <Route
                    path=""
                    element={
                      <>
                        <CardAccountData />
                        <CardPersonalData />
                        <CardAddressData />
                      </>
                    }
                  />
                  <Route path="conta" element={<CardAccountData />} />
                  <Route path="dados-pessoais" element={<CardPersonalData />} />
                  <Route path="endereco" element={<CardAddressData />} />
                </Route>

                <Route
                  path="cadastrar/:step"
                  element={<RegisterPhoneWizard />}
                ></Route>

                <Route path="alterar-senha" element={<EditPassword />} />
                <Route path="criar-senha" element={<UserCreatePassword />} />
              </Route>

              <Route
                path="menu-de-conta/preferencias-de-comunicacao"
                element={<ManagerPreferencesProvider />}
              >
                <Route path="" element={<ManageCommunicationPreferences />}>
                  <Route
                    path=""
                    element={
                      <>
                        <CardTypeCommunicate />
                        <CardSubjectCommunicate />
                        <CardNotifications />
                      </>
                    }
                  />
                  <Route
                    path="tipos-de-comunicados"
                    element={<CardTypeCommunicate />}
                  />
                  <Route
                    path="gerenciar-assuntos-dos-comunicados"
                    element={<CardSubjectCommunicate />}
                  />
                  <Route path="notificacoes" element={<CardNotifications />} />
                </Route>
              </Route>
            </Route>

            <Route path="*" element={<Error />} />
          </Routes>
        </SocialMediaProvider>
      </ControllerFlowStepsProvider>
    </BrowserRouter>
  );
}
