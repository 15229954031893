import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import ButtonLabel from '../../../components/Button/ButtonLabel';
import Card from '../../../components/Card/Card';
import CheckForcePassword from '../../../components/CheckForcePassword/CheckForcePassword';
import Container from '../../../components/Container/Container';
import FormField from '../../../components/FormField/FormField';
import Icon from '../../../components/Icon/Icon';
import LogoText from '../../../components/LogoText/LogoText';
import { useAccountRecoveryContext } from '../../../data/AccountRecovery/AccountRecoveryContext';
import { PASS_MAX_LENGTH } from '../../../data/Constants';
import { useAuthContext } from '../../../hooks/AuthContext';
import { useControllerFlowStepsContext } from '../../../hooks/ControllerFlowStepsContext';
import { unlinkSocialAccount } from '../../../services/accountController';
import { updatePassword } from '../../../services/user';
import './CreatePassword.scss';
import { passwordMask } from '../../../utils/Masks';
import { ErrorMessages } from '../../../data/ErrorMessages';

export default function CreatePassword() {
  const { user } = useAuthContext();
  const { register, watch, errors, trigger, touchedFields, getValues } =
    useAccountRecoveryContext();
  const { showErrorMessageServer, setAlertBox } =
    useControllerFlowStepsContext();
  const [fieldsValidate, setValueValidateFields] = useState({
    password: '',
    checkPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [updatedSuccessPassword, setUpdatedSuccessPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location?.state?.provider) {
      goBack();
    }
  }, []);

  window.onpopstate = () => {
    goBack();
  };

  function goBack() {
    navigate('/menu-de-conta', { replace: true });
  }

  useEffect(() => {
    const subscription = watch((data: any) => {
      setValueValidateFields(data);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  async function checkValidateFields() {
    return await trigger(['password', 'checkPassword']);
  }

  async function setUnlinkSocialAccount() {
    try {
      await unlinkSocialAccount(user.email, location?.state?.provider);
    } catch (error) {
      console.error(error);
      setAlertBox({
        message:
          'Não foi possível desconectar sua conta. Tente novamente mais tarde',
        error: true
      });
    }
  }

  async function updatePasswordData() {
    const isValidFields = await checkValidateFields();
    if (isValidFields) {
      setLoading(true);
      const password = getValues('password');
      try {
        await updatePassword(
          user.email,
          password,
          ErrorMessages.CREATE_ACCOUNT_PASSWORD
        );
        setUnlinkSocialAccount();
        setUpdatedSuccessPassword(true);
      } catch (_) {
        showErrorMessageServer();
        setLoading(false);
      }
    }
  }

  function redirectMenu() {
    navigate('/menu-de-conta', { replace: true });
  }

  function mountSuccessView() {
    return (
      <div className="flex flex-column width-full">
        <LogoText />

        <div className="flex flex-column mt-16 menu-account-pass success-card ">
          <Icon name="success" />
          <h2 className="mbi-16">tudo certo!</h2>

          <span className="md mb-24 color-text-default">
            Sua senha da Conta SBT foi criada!
          </span>

          <Button className="mt-8" full onClick={redirectMenu}>
            <ButtonLabel>concluir</ButtonLabel>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Container>
      <Card width="400px" minHeight="550px" center>
        {updatedSuccessPassword ? (
          mountSuccessView()
        ) : (
          <div className="flex flex-column width-full">
            <LogoText />
            <h2 className="color-text-default">criar senha</h2>
            <span className="md mt-4 color-text-default">
              Crie uma senha para acessar sua conta
            </span>

            <div className="flex flex-column mt-16">
              <div className="form-container">
                <CheckForcePassword
                  value={fieldsValidate.password}
                  errorField={!errors.password}
                >
                  <FormField
                    name="password"
                    label="nova senha"
                    type="password"
                    maxLength={PASS_MAX_LENGTH}
                    placeholder="digite sua senha"
                    register={register('password')}
                    onInput={passwordMask}
                    error={errors}
                    touchedFields={touchedFields}
                    sufix
                    isPassword
                  />
                </CheckForcePassword>
              </div>
              <div className="form-container">
                <FormField
                  name="checkPassword"
                  label="confirme a nova senha"
                  maxLength={PASS_MAX_LENGTH}
                  type="password"
                  placeholder="repita sua senha"
                  register={register('checkPassword')}
                  onInput={passwordMask}
                  error={errors}
                  touchedFields={touchedFields}
                  sufix
                  isPassword
                />
              </div>

              <Button
                className="mt-24 mbi-0"
                full
                disabled={
                  loading ||
                  fieldsValidate.password?.length < 6 ||
                  !!errors.password ||
                  !touchedFields.password ||
                  !!errors.checkPassword ||
                  fieldsValidate.checkPassword?.length < 6
                }
                onClick={updatePasswordData}
              >
                <ButtonLabel loading={loading}>avançar</ButtonLabel>
              </Button>
              <Button
                className="mt-16 mbi-0"
                full
                customColors="btn-white"
                disabled={loading}
                onClick={goBack}
              >
                <ButtonLabel>voltar</ButtonLabel>
              </Button>
            </div>
          </div>
        )}
      </Card>
    </Container>
  );
}
