import './LogoText.scss';
import Icon from '../Icon/Icon';

export default function LogoText() {
  return (
    <div className="logo-text">
      <Icon name="logoSbt" />
      <span>Conta SBT</span>
    </div>
  );
}
