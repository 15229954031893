import Icon from '../Icon/Icon';
import './Menu.scss';

export default function MenuItem(props: any) {
  return (
    <div className="menu-button-item" onClick={props.action}>
      {props.children}
      {props.icon ? <Icon name={props.icon} /> : false}
    </div>
  );
}
