import { createContext, useContext } from 'react';
import { useState } from 'react';

export interface ControlNavBarContextData {
  setGoBackNavigate: React.Dispatch<
    React.SetStateAction<{
      path: string | number;
      param: object;
      condition?: any;
    }>
  >;
  goBackNavigate: {
    path: string | number;
    param: object;
    condition?: any;
  };
  showButton: boolean;
  setShowButton: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ControlNavBarContext = createContext(
  {} as ControlNavBarContextData
);

const ControlNavBarProvider = ({ children }: any) => {
  const [goBackNavigate, setGoBackNavigate] = useState<any>({
    path: '',
    param: {},
    condition: null
  });
  const [showButton, setShowButton] = useState(false);

  return (
    <ControlNavBarContext.Provider
      value={{
        setGoBackNavigate,
        goBackNavigate,
        showButton,
        setShowButton
      }}
    >
      {children}
    </ControlNavBarContext.Provider>
  );
};

export const useControlNavBarContext = () => useContext(ControlNavBarContext);

export default ControlNavBarProvider;
