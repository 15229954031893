import './SocialMedia.scss';
import ButtonGoogle from './ButtonGoogle';
import ButtonFace from './ButtonFace';
import ButtonApple from './ButtonApple';

interface SocialMediaProps {
  context: any;
  label: string;
}

export default function SocialMedia({ context, label }: SocialMediaProps) {
  return (
    <div className="box-social">
      <div className="social-medias">
        <ButtonFace />
        <ButtonApple />
        <ButtonGoogle />
      </div>

      <div className="box-social__text">
        <hr />
        <span className="md">{label}</span>
        <hr />
      </div>
    </div>
  );
}
